import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import ControlledSelectField from "../../ControlledSelectField";
import DeleteContent from "../DeleteContent";
import {contentService} from "../../../api/services/contentService";
import ControlledButtonFileInput from "../../ControlledButtonFileInput";
import ToggleCodeButton from "../ToggleCodeButton";
import ContentCodeArea from "../ContentCodeArea";
import ContentPreview from "../ContentPreview";
import CodeArea from "../CodeArea";
import CenteredLoader from "../../Loaders/CenteredLoader";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import {option} from "../../../utils/selectFIeldHelpers";
import ContentSaveAsNewButton from "../ContentSaveAsNewButton";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModal from "../../CustomModal/CustomModal";
import ContentModalHelperText from "./ContentModalHelperText";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {offerService} from "../../../api/services/offerService";

const schema = yup.object().shape({
    offer: option.required('Required field'),
    subject_line: yup.string().required('Required field'),
    from_name: yup.string().required('Required field'),
    content_code: yup.string().required('Required field'),
    content_name: yup.string().required('Required field'),
    preheader: yup.string().required('Required field').max(100),
});

const EditContentModal = (
    {
        setSnack,
        open,
        handleClose,
        handleRefreshItems,
        item
    }) => {
    const [loading, setLoading] = useState(false)
    const [viewCode, setViewCode] = useState(false)
    const [loadingCode, setLoadingCode] = useState(true)

    const [loadingSaveAsNew, setLoadingSaveAsNew] = useState(false)

    const {
        control,
        handleSubmit,
        setValue,
        formState,
        getFieldState,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            offer: item.offer ? {
                key: item.offer.name,
                value: item.offer.id
            } : null,
            subject_line: item.subject_line,
            from_name: item.from_name,
            content_code: "",
            content_name: item.name,
            preheader: item.preheader
        }
    })

    useEffect(() => {
        fetchContentCode()
    }, []);

    const fetchContentCode = () => {
        contentService.fetchItemFile(item.id)
            .then((response) => {
                setLoadingCode(false);
                setValue("content_code", response.data.html)
                // setOffers(response.data);
            })
            .catch((errorResponse) => {
                setLoadingCode(false);
                setSnack(errorResponse.message, "error");
            });
    }


    const toggleViewCode = () => {
        setViewCode(!viewCode)
    }

    const onSuccess = (data) => {
        setLoading(true)
        const subject_line = getFieldState("subject_line").isDirty && data.subject_line
        const from_name = getFieldState("from_name").isDirty && data.from_name
        const offer_id = getFieldState("offer").isDirty && data.offer.value
        const content_code = getFieldState("content_code").isDirty && data.content_code
        const content_name = getFieldState("content_name").isDirty && data.content_name
        const preheader = getFieldState("preheader").isDirty && data.preheader

        contentService.updateItem(
            {
                content_name,
                from_name,
                preheader,
                offer_id,
                content_code,
                subject_line
            },
            item.id,)
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })
    }


    const onSaveAsNew = () => {

        const data = getValues()

        setLoadingSaveAsNew(true)
        const subject_line = data.subject_line
        const from_name = data.from_name
        const offer_id = data.offer.value
        const content_code = data.content_code
        const content_name = data.content_name
        const preheader = data.preheader

        contentService.createItem(
            content_name,
            subject_line,
            from_name,
            offer_id,
            preheader,
            content_code,
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoadingSaveAsNew(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoadingSaveAsNew(false)
            })
    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            maxWidth={"md"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Content"}/>
            <CustomModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            name={"offer"}
                            label={"Offer"}
                            apiCall={offerService.getItems}
                            control={control}
                            ignoreInput={true}
                            disableCloseOnSelect={false}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledTextField
                            control={control}
                            name={"content_name"}
                            label={"Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"subject_line"}
                            label={"Subject Line"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"from_name"}
                            label={"From Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"preheader"}
                            label={"Preheader"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ContentModalHelperText/>
                    </Grid>
                    <Grid item xs={4}>
                        <ControlledButtonFileInput
                            control={control}
                            name={"content_code"}
                            formState={formState}
                            setValue={setValue}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ToggleCodeButton
                            viewCode={viewCode}
                            onChange={toggleViewCode}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CodeArea>
                            {
                                loadingCode ? <CenteredLoader/> :
                                    viewCode ?
                                        <ContentCodeArea
                                            control={control}
                                            name={"content_code"}
                                            setValue={setValue}
                                        /> :
                                        <ContentPreview control={control} name={"content_code"}/>
                            }
                        </CodeArea>

                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={4}>
                    <ContentSaveAsNewButton onSaveAsNew={onSaveAsNew} loading={loadingSaveAsNew}/>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <SubmitButton
                        text={"Save Content"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditContentModal);