import React, {useEffect, useState} from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@mui/material";
import ChartCard from "./ChartCard";
import {formatCurrency} from "../../utils/curencyUtils";

import {ChartsXAxis} from '@mui/x-charts/ChartsXAxis';
import {ChartsYAxis} from '@mui/x-charts/ChartsYAxis';
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import {useDropdownSelect} from "./useDropdownSelect";
import useApi from "../../hooks/useApi";
import {dashboardService} from "../../api/services/dashboardService";
import CenteredLoader from "../Loaders/CenteredLoader";
import { ResponsiveContainer } from 'recharts';

const data = [
    {
        date: '2025-01-01',
        delivery_rate: 99.72,
        open_rate: 52.34,
        unique_open_rate: 45.34,
        unique_clicks: 10435,
        unique_click_rate: 50,
        complaints_rate: 0.01,
        unsubscribes_rate: 0.03
    },
    {
        date: '2025-01-02',
        delivery_rate: 99.65,
        open_rate: 51.20,
        unique_open_rate: 44.10,
        unique_clicks: 10300,
        unique_click_rate: 49,
        complaints_rate: 0.02,
        unsubscribes_rate: 0.04
    },
    {
        date: '2025-01-03',
        delivery_rate: 99.80,
        open_rate: 53.00,
        unique_open_rate: 46.00,
        unique_clicks: 10500,
        unique_click_rate: 51,
        complaints_rate: 0.01,
        unsubscribes_rate: 0.02
    },
    {
        date: '2025-01-04',
        delivery_rate: 99.60,
        open_rate: 50.50,
        unique_open_rate: 43.50,
        unique_clicks: 10200,
        unique_click_rate: 48,
        complaints_rate: 0.03,
        unsubscribes_rate: 0.05
    },
    {
        date: '2025-01-05',
        delivery_rate: 99.75,
        open_rate: 52.80,
        unique_open_rate: 45.80,
        unique_clicks: 10450,
        unique_click_rate: 50,
        complaints_rate: 0.02,
        unsubscribes_rate: 0.03
    },
    {
        date: '2025-01-06',
        delivery_rate: 99.70,
        open_rate: 51.90,
        unique_open_rate: 44.90,
        unique_clicks: 10350,
        unique_click_rate: 49,
        complaints_rate: 0.01,
        unsubscribes_rate: 0.04
    },
    {
        date: '2025-01-07',
        delivery_rate: 99.68,
        open_rate: 52.10,
        unique_open_rate: 45.10,
        unique_clicks: 10400,
        unique_click_rate: 50,
        complaints_rate: 0.02,
        unsubscribes_rate: 0.03
    },

]

const valueFormatter = (value) => value + '%';


const METRICS = [
    {key: 'delivery_rate', label: 'Delivery Rate', disableLine: false},
    {key: 'open_rate', label: 'Open Rate', disableLine: false},
    {key: 'unique_open_rate', label: 'Unique Open Rate', disableLine: false},
    {key: 'unique_click_rate', label: 'Unique Click Rate', disableLine: false},
    {key: 'complaints_rate', label: 'Complaints Rate', disableLine: false},
    {key: 'unsubscribes_rate', label: 'Unsubscribes Rate', disableLine: false},
];

const periods = [
    {label: '7 days', key: 7},
    {label: '30 days', key: 30},
    {label: '60 days', key: 60},
    {label: '90 days', key: 90},
]

const DailyCampaignRatesChartCard = () => {
    // State: which metrics are currently selected (by dataKey).
    const [selectedMetrics, metricsSelector] = useDropdownSelect(
        METRICS, "Metrics", true
    );

    const [selectedPeriod, periodSelector] = useDropdownSelect(
        periods, "Period"
    );

    const [data, setData] = useState([])

    const {loading, makeRequest} = useApi(
        dashboardService.getDailyEspStatsRates,
        true
    )

    useEffect(() => {
        makeRequest(
            selectedPeriod,
            (response) => {
                setData(response.data.reverse())
            }
        )
    }, [selectedPeriod]);

    // Build the `series` array dynamically based on selected metrics
    const chartSeries = METRICS
        .filter((m) => selectedMetrics.includes(m.key))
        .map((m) => ({
            dataKey: m.key,
            label: m.label,
            disableLine: m.disableLine,
            valueFormatter: valueFormatter
        }));

return (
    <ChartCard
        label={'Daily Campaign Rates'}
        metricsSelector={metricsSelector}
        periodSelector={periodSelector}
        infoTooltipText={"This chart displays daily campaign rates, showing performance metrics as percentages over time."}
    >
        {loading ? <CenteredLoader height={500}/> : (
            <Box sx={{ width: '100%', height: 500 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        grid={{ vertical: true, horizontal: true }}
                        dataset={data}
                        xAxis={[{ dataKey: 'date', scaleType: 'band' }]}
                        yAxis={[{ scaleType: 'linear', label: 'Revenue', min: 0 }]}
                        series={chartSeries}
                        sx={{
                            [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translate(-45px, 0)' },
                            [`.${axisClasses.right} .${axisClasses.label}`]: { transform: 'translate(30px, 0)' },
                        }}
                        slotProps={{ legend: { itemGap: 20 } }}
                        margin={{ left: 90, right: 0, top: 100 }}
                    />
                </ResponsiveContainer>
            </Box>
        )}
    </ChartCard>
    );
};

export default React.memo(DailyCampaignRatesChartCard);