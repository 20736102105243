import React, {useEffect, useState} from 'react';
import {Box, IconButton} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import {offerService} from "../api/services/offerService";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import dayjs from "dayjs";
import {contentReportingUngroupedService} from "../api/services/contentReportingUngroupedService";
import {contentService} from "../api/services/contentService";
import {networkIntegrationService} from "../api/services/networkIntegrationService";

const ContentReportingUngrouped = () => {
    const urlParams = useUrlParams()

    const [modalOpen, setModalOpen] = useState(null)

    const handleModalClose = () => {
        setModalOpen(null)
    }

    const handleModalOpen = (content_id) => {
        setModalOpen(content_id)
    }

    const {
        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        contentReportingUngroupedService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            created_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Range Date Start",
                columns: 6,
                disableFuture: true,
                defaultValue: dayjs().startOf('month')
            },
            created_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Range Date End",
                columns: 6,
                disableFuture: true
            },
            // content_id: {
            //     label: "Content",
            //     apiCall: contentService.getItems,
            //     type: filtersElementsTypes.MULTISELECT_API,
            // },
            network_integration_id: {
                label: "Network Integration",
                apiCall: networkIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            offer_id: {
                label: "Offer",
                apiCall: offerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
        },
        {},
        {
            sortBy: "revenue",
            sortOrder: "desc"
        },
        urlParams.queryParams
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'content_name',
            // numeric: false,
            disablePadding: false,
            label: 'Content Name',
            width: "195px",
            highlight: true
        },
        {
            id: 'offer_name',
            numeric: false,
            disablePadding: false,
            label: 'Offer name',
            width: "170px"
        },
        // {
        //     id: 'network_integration_name',
        //     numeric: false,
        //     disablePadding: false,
        //     label: 'Network Integration name',
        //     width: "170px"
        // },
        {
            id: 'clicks',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Clicks',
            width: "100px",
            totals: totals.total_clicks
        },
        {
            id: 'conversions',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Conversions',
            width: "100px",
            totals: totals.total_conversions
        },
        {
            id: 'epc',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'EPC',
            width: "100px",
            trailingSign: "$",
            totals: totals.total_epc
        },
        {
            id: 'revenue',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Revenue',
            width: "100px",
            float: true,
            trailingSign: "$",
            totals: totals.total_revenue
        },
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                totals={totals}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        <ContentReportingPreviewModal
            open={modalOpen}
            handleClose={handleModalClose}
        />
    </>
}

export default ContentReportingUngrouped