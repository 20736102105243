import React, {useEffect, useState} from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {Box, Grid, Paper, Typography} from "@mui/material";
import ChartCard from "./ChartCard";
import {formatCurrency} from "../../utils/curencyUtils";

import {ChartsXAxis} from '@mui/x-charts/ChartsXAxis';
import {ChartsYAxis} from '@mui/x-charts/ChartsYAxis';
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import useApi from "../../hooks/useApi";
import {dashboardService} from "../../api/services/dashboardService";
import CenteredLoader from "../Loaders/CenteredLoader";
import {ResponsiveContainer} from "recharts";

const data = [
    {date: '2025-01', revenue: 320432, avg: 330456},
    {date: '2025-02', revenue: 380456, avg: 330456},
    {date: '2025-03', revenue: 329435, avg: 330456},
    {date: '2025-04', revenue: 350345, avg: 330456},
    {date: '2025-05', revenue: 340324, avg: 330456},
    {date: '2025-06', revenue: 310245, avg: 330456},
    {date: '2025-07', revenue: 143534, avg: 330456},
]

const MonthlyRevenueChartCard = ({label = 'Daily Revenue'}) => {

        const [data, setData] = useState([])

    const {loading, makeRequest} = useApi(dashboardService.getMonthlyRevenue, true)

    useEffect(() => {
        makeRequest(
            null,
            (response) => {
                setData(response.data.reverse())
            }
        )
    }, []);

    return (
        <ChartCard label={'Monthly Revenue'}
                   infoTooltipText={"This chart displays your monthly revenue trends. It shows the total revenue earned each month, along with the average monthly revenue. Use this chart to track your revenue performance over time."}>
            {loading ? <CenteredLoader height={400}/> : (
                <Box sx={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            grid={{ vertical: true, horizontal: true }}
                            dataset={data}
                            xAxis={[{
                                dataKey: 'date',
                                scaleType: 'band',
                            }]}
                            yAxis={[{
                                scaleType: 'linear',
                                label: 'Revenue',
                                min: 0,
                                valueFormatter: (value) => formatCurrency(value, false),
                            }]}
                            series={[
                                { dataKey: 'revenue', label: 'Revenue' },
                                { dataKey: 'avg', label: `Avg. Revenue`, showMark: false }
                            ]}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translate(-45px, 0)' },
                                [`.${axisClasses.right} .${axisClasses.label}`]: { transform: 'translate(30px, 0)' },
                            }}
                            slotProps={{ legend: { itemGap: 20 } }}
                            margin={{ left: 90, right: 0 }}
                        />
                    </ResponsiveContainer>
                </Box>
            )}
        </ChartCard>
    );
};

export default React.memo(MonthlyRevenueChartCard);