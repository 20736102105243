import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';


const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        const data = {
            page: args.page,
            name: args.name,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
        }

        if (args.network_id) {
            // const ids = args.network_id.map(network => network.value)
            data.network_id = args.network_id.map(network => network.value)
        }

        if (args.network_integration_id) {
            // const ids = args.network_integration_id.map(network => network.value)
            data.network_integration_id = args.network_integration_id.map(network => network.value)
        }

        if (args.status !== undefined && args.status !== null) {
            data.status = typeof args.status === 'object' && 'value' in args.status
                ? args.status.value
                : args.status;
        }

        apiClient.get(endpoints.networkIntegration, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }
        }).then((response) => {
            resolve(response.data)
        }).catch((errorResponse) => {
            reject(errorResponse.data)
        })

    })
}

const createItem = (network_id, api_key, name, external_account, additional_field_1, status) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            network_id,
            api_key,
            name,
            external_account,
            additional_field_1,
            status
        }

        apiClient.post(endpoints.networkIntegration, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItem = (network_integration_id, api_key, name, external_account, additional_field_1, status) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            api_key,
            name,
            external_account,
            additional_field_1,
            status
        }

        apiClient.patch(endpoints.networkIntegration + "/" + network_integration_id, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateItemStatus = (network_integration_id, status) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = { status };
        console.log('status', status);
        console.log('network_integration_id', network_integration_id);

        const url = `${endpoints.networkIntegration}/${network_integration_id}/status`;
        console.log('url', url);

        apiClient.patch(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};


const deleteItem = ({itemId}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.networkIntegration + "/" + itemId)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteItems = (items) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            items_to_delete: Object.keys(items),
        }

        apiClient.post(endpoints.networkIntegration + "/delete-multiple", data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const networkIntegrationService = {
    getItems,
    createItem,
    updateItem,
    updateItemStatus,
    deleteItem,
    deleteItems
}
