const login = "/auth/login"
const homePage = "/"
const networkIntegration = "/network-integrations"
const offers = "/offers"
const contents = "/contents"
const categories = "/categories"
const espIntegrations = "/esp-integrations"
const brands = "/brands"
const verticals = "/verticals"
const tags = "/tags"
const templates = "/templates"
const mailingLists = "/lists"
const configs = "/config"
const mailingListsGroups = "/lists-groups"
const manualSchedule = "/manual-schedule"
const campaigns = "/campaign"
const contentReporting = "/content-reporting"
const contentReportingUngrouped = "/content-reporting-ungrouped"
const campaignReportingUngrouped = "/campaign-reporting-ungrouped"
const mailingListReportingUngrouped = "/mailing-list-reporting-ungrouped"
const offerReportingUngrouped = "/offer-reporting-ungrouped"
const campaignReporting = "/campaign-reporting"
const offerReporting = "/offer-reporting"
const mailingListReporting = "/mailing-list-reporting"
const automatedSchedulingConfigs = "/automated-scheduling-configs"
const externalSources = "/external-sources"
const externalSourceTypes = "/external-source-types"
const externalSourceReporting = "/external-source-reporting"
const internalSourceReporting = "/internal-source-reporting"
const unknownSourceReporting = "/unknown-source-reporting"
const partnerReporting = "/partner-reporting"
const dynamicTag = "/dynamic-tag"
const networkIntegrationReporting = "/network-integration-reporting"
const brandDnmContact = "/brand-dnm-contact"


export const routes = {
    login,
    homePage,
    networkIntegration,
    offers,
    contents,
    categories,
    espIntegrations,
    brands,
    verticals,
    tags,
    templates,
    mailingLists,
    configs,
    mailingListsGroups,
    manualSchedule,
    campaigns,
    contentReporting,
    contentReportingUngrouped,
    campaignReportingUngrouped,
    mailingListReportingUngrouped,
    offerReportingUngrouped,
    campaignReporting,
    offerReporting,
    mailingListReporting,
    automatedSchedulingConfigs,
    externalSources,
    externalSourceTypes,
    externalSourceReporting,
    unknownSourceReporting,
    internalSourceReporting,
    partnerReporting,
    dynamicTag,
    networkIntegrationReporting,
    brandDnmContact
}
