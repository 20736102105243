import React, {useState} from 'react';
import {Box, Button, Drawer, Grid, Typography} from '@mui/material';
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import StatsCard from "../components/Dashboard/StatsCard";
import {formatCurrency} from "../utils/curencyUtils";
import ChartCard from "../components/Dashboard/ChartCard";
import DailyRevenueChartCard from "../components/Dashboard/DailyRevenueChartCard";
import RevenueStatsCards from "../components/Dashboard/RevenueStatsCards";
import MonthlyRevenueChartCard from "../components/Dashboard/MonthlyRevenueChartCard";
import DailyCampaignStatsChartCard from "../components/Dashboard/DailyCampaignStatsChartCard";
import DailyCampaignRatesChartCard from "../components/Dashboard/DailyCampaignRatesChartCard";
import UnsubscribeStatsCards from "../components/Dashboard/UnsubscribeStatsCards";
import ContactsStatsCards from "../components/Dashboard/ContactsStatsCards";
import BestOffersCard from "../components/Dashboard/BestOffersCard";
import { useMediaQuery, useTheme } from '@mui/material';


const Dashboard = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box>
            <CustomAppBar/>
            <Grid container sx={{ pt: 2 }} spacing={2} direction={isSmallScreen ? "column" : "row"}>
                <Grid container item xs={12} spacing={2}>
                    <RevenueStatsCards/>
                    <DailyRevenueChartCard/>
                    <MonthlyRevenueChartCard/>
                    <DailyCampaignStatsChartCard/>
                    <DailyCampaignRatesChartCard/>
                    <BestOffersCard/>
                </Grid>
            </Grid>
        </Box>
    );
};


export default Dashboard;