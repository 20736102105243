import React, {useEffect, useState} from 'react';
import {
    Box, Collapse,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton, ListItemIcon,
    ListItemText, Paper,
    styled,
    Toolbar, useTheme
} from "@mui/material";
import {Link, NavLink, useLocation} from "react-router-dom";
import {drawerWidth, miniDrawerWidth} from "../../utils/drawerSettings";
import {ChevronLeft, ChevronRight, ExpandLess, ExpandMore, Inbox, StarBorder} from "@mui/icons-material";
import {navItems} from "../../navigation/navItems";
import Logo from "../../assets/images/logo.png"

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    // overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // overflowX: 'hidden',
    width: `calc(${miniDrawerWidth})`,
    [theme.breakpoints.up('sm')]: {
        width: 100,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...theme.mixins.toolbar,
}));

const createNavCollapsibleItem = (menuItems, index, open, theme, openMenus, handleOpenMenu, level = 0) => {
    const isOpen = openMenus.includes(index);

    const renderChildren = (children, parentIndex, childLevel) => {
        if (!children) return null;

        return children.map((item, childIndex) => {
            const childKey = `${parentIndex}-${childIndex}`;
            if (item.collapsible) {
                return createNavCollapsibleItem(item, childKey, open, theme, openMenus, handleOpenMenu, childLevel + 1);
            } else {
                return createNavItem(item, childKey, open, theme, true, childLevel + 1);
            }
        });
    };

     return (
        <React.Fragment key={index}>
            <ListItem disablePadding>
                <ListItemButton
                    onClick={() => handleOpenMenu(index)}
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        paddingLeft: theme.spacing(1+level/4),

                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 1,
                            justifyContent: 'center',
                            color: "inherit"
                        }}
                    >
                        {menuItems.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={menuItems.label}
                        primaryTypographyProps={{ variant: "menuItem" }}
                        sx={{ opacity: open ? 1 : 0 }}
                    />
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            justifyContent: 'center',
                            color: "inherit"
                        }}
                    >
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {renderChildren(menuItems.collapsible, index, level)}
                </List>
            </Collapse>
        </React.Fragment>
    );
}

const createNavItem = (item, index, open, theme, subItem = false, level=0) => {
    return <ListItemButton
        key={index}
        component={NavLink}
        to={item.path}
        style={({isActive}) => {
            const additionalProps = {}
            if (isActive) {
                additionalProps.backgroundColor = theme.palette.primary.dark
                additionalProps.pointerEvents = "none";
                // additionalProps.opacity = 0.5;
            }
            // if (subItem) {
            //     additionalProps.pl = 2
            // }
            return {
                borderRadius: theme.shape.borderRadius,
                paddingLeft: theme.spacing(1+level/4),
                ...additionalProps
            }
        }}
    >

        <ListItemIcon
            sx={{
                minWidth: 0,
                mr: 1,
                justifyContent: 'center',
                color: "inherit",
                pl: subItem ? "5px" : 0,
            }}
        >

            {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.label} primaryTypographyProps={{variant: "menuItem"}}
                      sx={{opacity: open ? 1 : 0}}/>
    </ListItemButton>
}

const CustomDrawer = ({open, handleDrawerToggle}) => {
    const theme = useTheme()
    const [openMenus, setOpenMenus] = useState(() => {
        const savedMenus = sessionStorage.getItem('openMenus');
        return savedMenus ? JSON.parse(savedMenus) : [];
    });
    const container = window !== undefined ? () => window.document.body : undefined;

    let location = useLocation();

    useEffect(() => {
        sessionStorage.setItem('openMenus', JSON.stringify(openMenus));
    }, [openMenus]);

    const handleOpenMenu = (menuIndex) => {
        setOpenMenus(prevOpenMenus => {
            // Check if the menu is already open
            const isMenuOpen = prevOpenMenus.includes(menuIndex);
            if (isMenuOpen) {
                // If open, filter it out to close it
                return prevOpenMenus.filter(id => id !== menuIndex);
            } else {
                // If not open, add it to the array to open it
                return [...prevOpenMenus, menuIndex];
            }
        });
    }

    return (

        <Paper
            sx={{
                width: drawerWidth,
                flexShrink: 1,
                py: 2,
                // pr: 0,
                m: 2,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(theme.palette.primary.main),
                ...(open && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                }),
                ...(!open && {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                }),
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>
                <img src={Logo} alt="Example" width={55} height={55}
                     style={{borderRadius: theme.shape.borderRadius, margin: "auto"}}/>
            </DrawerHeader>

            <Box sx={{position: "relative", py: 2}}>
                <Paper sx={{position: "absolute", right: -20, top: -10}}>
                    <IconButton onClick={handleDrawerToggle}>
                        {open ? <ChevronLeft/> : <ChevronRight/>}
                    </IconButton>
                </Paper>
            </Box>

            <List sx={{maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden", ml: 2, pr: "8px"}}>
                {navItems.map((item, index) => {
                    if (item.path) {
                        return createNavItem(item, index, open, theme)
                    } else {
                        return createNavCollapsibleItem(item, index, open, theme, openMenus, handleOpenMenu)
                    }
                })}
            </List>
        </Paper>
    );
};

export default CustomDrawer;