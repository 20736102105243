import React from 'react';
import CustomSwitch from "./CustomSwitch";
import useModal from "../hooks/useModal";
import ConfirmationModal from "./ConfirmationModal";

const CustomSwitchWithConfirmation = ({
    title,
    description,
    item,
    api,
    handleSubmit,
    statusField = "is_active"
}) => {
    const confirmationModal = useModal();
    const currentStatus = item[statusField];

    const modal = (
        <ConfirmationModal
            title={title}
            description={description}
            handleClose={confirmationModal.handleModalClose}
            handleSubmit={() => {
                handleSubmit(item, confirmationModal.handleModalClose);
            }}
            open={confirmationModal.modalOpen}
            loading={api.loading}
        />
    );

    return (
        <>
            <CustomSwitch
                sx={{ m: 1 }}
                checked={currentStatus}
                onChange={confirmationModal.handleModalOpen}
            />
            {modal}
        </>
    );
};

export default CustomSwitchWithConfirmation;