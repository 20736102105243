import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import ControlledSelectField from "../../ControlledSelectField";
import {offerService} from "../../../api/services/offerService";
import {contentService} from "../../../api/services/contentService";
import {checkHtmlFormat, checkSize} from "../../../utils/formUtils";
import ControlledButtonFileInput from "../../ControlledButtonFileInput";
import ContentCodeArea from "../ContentCodeArea";
import ToggleCodeButton from "../ToggleCodeButton";
import ContentPreview from "../ContentPreview";
import CodeArea from "../CodeArea";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import ContentModalHelperText from "./ContentModalHelperText";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";

const option = yup.object({
    key: yup.string().required('Key is required'),
    value: yup.number().typeError('Value must be a number').required('Value is required'),
});

const schema = yup.object().shape({
    name: yup.string().required('Required field'),
    offer: option.required('Required field'),
    subject_line: yup.string().required('Required field'),
    from_name: yup.string().required('Required field'),
    preheader: yup.string().required('Required field').max(100),
    content_code: yup.string().required('Required field'),
});

const AddContentModal = (
    {
        setSnack,
        open,
        handleClose,
        handleRefreshItems
    }) => {
    const [loading, setLoading] = useState(false)
    const [viewCode, setViewCode] = useState(true)
    const {
        control,
        handleSubmit,
        reset,
        formState,
        setValue,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            offer: null,
            subject_line: "",
            from_name: "",
            content_code: "",
            name: "",
            preheader: ""
        }
    })


    const toggleViewCode = () => {
        setViewCode(!viewCode)
    }

    const onSuccess = (data) => {
        setLoading(true)

        contentService.createItem(
            data.name,
            data.subject_line,
            data.from_name,
            data.offer.value,
            data.preheader,
            data.content_code
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                reset({
                    offer: null,
                    subject_line: "",
                    from_name: "",
                    name: "",
                    preheader: "",
                    content_code: null
                })
                setViewCode(true)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            maxWidth={"md"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create Content"}/>
            <CustomModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            name={"offer"}
                            label={"Offer"}
                            apiCall={offerService.getItems}
                            control={control}
                            ignoreInput={true}
                            disableCloseOnSelect={false}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledTextField
                            control={control}
                            name={"name"}
                            label={"Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"subject_line"}
                            label={"Subject Line"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"from_name"}
                            label={"From Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"preheader"}
                            label={"Preheader"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ContentModalHelperText/>
                    </Grid>
                    <Grid item xs={4}>
                        <ControlledButtonFileInput
                            control={control}
                            name={"content_code"}
                            formState={formState}
                            setValue={setValue}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ToggleCodeButton
                            viewCode={viewCode}
                            onChange={toggleViewCode}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CodeArea>
                            {
                                viewCode ?
                                    <ContentCodeArea
                                        control={control}
                                        name={"content_code"}
                                        setValue={setValue}
                                    /> :
                                    <ContentPreview control={control} name={"content_code"}/>
                            }
                        </CodeArea>
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create Content"}
                        loading={loading}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddContentModal);