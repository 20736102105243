import React, {useCallback, useEffect, useState} from 'react';
import {Box} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import AddNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/AddNetworkIntegrationModal";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {Delete, Edit} from "@mui/icons-material";
import ConfirmationModal from "../components/ConfirmationModal";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {filtersElementsTypes} from "../utils/constants";
import EditNetworkIntegrationModal
    from "../components/NetworkIntegration/NetworkIntegrationModal/EditNetworkIntegrationModal";
import useUrlParams from "../hooks/useUrlParams";
import useApi from "../hooks/useApi";
import useFilters from "../hooks/useFilters";
import usePagination from "../hooks/usePagination";
import useSort from "../hooks/useSort";
import {useDispatch} from "react-redux";
import {setSnack} from "../store/actions/snack";
import Filters from "../components/Filters/Filters";
import {networkService} from "../api/services/networkService";
import useData from "../hooks/useData";
import {categoryService} from "../api/services/categoryService";
import useSelectableData from "../hooks/useSelectableData";
import useDeleteData from "../hooks/useDeleteData";
import useDeleteMultipleData from "../hooks/useDeleteMultipleData";
import useUpdateData from "../hooks/useUpdateData";
import useAddData from "../hooks/useAddData";
import CustomSwitchWithConfirmation from "../components/CustomSwitchWithConfirmation";
import {offerService} from "../api/services/offerService";

const NetworkIntegrations = () => {
    const urlParams = useUrlParams()

    const {
        items,
        itemsPagination,
        itemsSort,
        itemsFilters,
        handleRefreshItems,
        fetchItemsApi,
    } = useData(
        networkIntegrationService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            name: {
                type: filtersElementsTypes.TEXT,
                label: "Name",
            },
            network_id: {
                label: "Networks",
                apiCall: networkService.getItems,
                searchField: "name",
                setOptionLabel: (item) => item.name,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            status: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Active", value: true},
                    {key: "Inactive", value: false}
                ],
                label: "Status"
            },
        },
        {},
        {},
        urlParams.queryParams
    );

    const {selectedItems, handleSelectItem, setSelectedItems} = useSelectableData()

    const switchButtonApi = useApi(networkIntegrationService.updateItemStatus)
    const {
        deleteItem,
        handleOpenDeleteItemModal,
        handleCloseDeleteItemModal,
        handleConfirmDeleteItem,
        deleteItemApi,
    } = useDeleteData(
        networkIntegrationService.deleteItem,
        handleRefreshItems,
        handleSelectItem
    )

    const {
        deleteItems,
        handleOpenDeleteMultipleItemsModal,
        handleCloseDeleteMultipleItemsModal,
        handleDeleteMultipleItems,
        deleteItemsApi,
    } = useDeleteMultipleData(
        networkIntegrationService.deleteItems,
        selectedItems,
        handleRefreshItems,
        setSelectedItems
    )

    const {
        updateItemId,
        updateItemData,
        handleOpenEditItemModal,
        handleCloseEditItemModal,
    } = useUpdateData(
        networkIntegrationService.getItems,
        urlParams.queryParams,
        "network_integration_id"
    )

    const {
        createItemModalOpen,
        handleOpenCreateItemModal,
        handleCloseCreateItemModal,
    } = useAddData()

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
            {edit: updateItemId?.value}
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
        updateItemId
    ])
    // network_id: {
    //     label: "Brands",
    //     apiCall: brandService.fetchAllBrands,
    //     apiFilters: {
    //         esp_integration_id: urlParams.queryParams.esp_integration_id
    //     },
    //     defaultValue: urlParams.queryParams.brand_id || [],
    //     searchField: "from_email",
    //     setOptionLabel: (item) => item.from_email,
    //     type: filtersElementsTypes.MULTISELECT,
    // },
    // esp_integration_id: {
    //     label: "ESP Integrations",
    //     apiCall: espIntegrationService.fetchAllEspIntegrations,
    //     apiFilters: {},
    //     defaultValue: urlParams.queryParams.brand_id || [],
    //     type: filtersElementsTypes.MULTISELECT,
    // },
    // subject_line: {
    //     label: "Subject Line",
    //     defaultValue: urlParams.queryParams.brand_id || "",
    //     type: filtersElementsTypes.TEXT,
    // }


    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
        },
        {
            id: 'network.name',
            numeric: false,
            disablePadding: false,
            label: 'Network',
            nested: true,
            width: "19%"
        },
        {
            id: 'external_account',
            numeric: false,
            disablePadding: false,
            label: 'Account',
            width: "10%"
        },
        {
            id: 'api_key',
            numeric: false,
            disablePadding: false,
            label: 'Api Key',
            width: "20%"
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            width: "7%",
            element: (item) => (
                <CustomSwitchWithConfirmation
                    title={"Status Change"}
                    description={"Are you sure you want to change this network integration?"}
                    item={item}
                    api={switchButtonApi}
                    statusField={"status"}
                    handleSubmit={(selectedItem, onSuccess) => {
                        const network_integration_id = selectedItem.id;
                        const status = !selectedItem.status;
                        networkIntegrationService.updateItemStatus(network_integration_id, status)
                            .then(() => {
                                handleRefreshItems();
                                onSuccess();
                            })
                            .catch((error) => {
                                console.error("Error updating status:", error);
                            });
                    }}
                />
            ),
        },
        {
            id: 'updated_at',
            align: "right",
            disablePadding: false,
            label: 'Updated At',
            datetime: true,
            width: "130px"
        },
        {
            id: 'created_at',
            align: "right",
            disablePadding: false,
            label: 'Created At',
            datetime: true,
            width: "130px"
        },
        {
            action: handleOpenEditItemModal,
            icon: <Edit/>,
        },
        {
            action: handleOpenDeleteItemModal,
            icon: <Delete/>,
        }
    ];

    const table = useTable(items, itemsPagination, selectedItems, handleSelectItem)

    return <>
        <Box>
            <CustomAppBar handleModalOpen={handleOpenCreateItemModal} addButtonText={"Create Network Integration"}
                          selectedItems={selectedItems}
                          onDeleteMultipleItems={handleOpenDeleteMultipleItemsModal}/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        {
            updateItemData && (<EditNetworkIntegrationModal
                open={true}
                networkIntegration={updateItemData}
                handleClose={handleCloseEditItemModal}
                fetchNetworkIntegrations={handleRefreshItems}
            />)
        }
        {
            createItemModalOpen &&
            <AddNetworkIntegrationModal
                handleClose={handleCloseCreateItemModal}
                fetchNetworkIntegrations={handleRefreshItems}
                open={true}
            />
        }
        {deleteItem &&
            <ConfirmationModal
                title={"Delete Network Integration"}
                description={
                    <>
                        Are you sure you want to delete the <strong>{deleteItem.name}</strong> network integration?
                    </>
                }
                handleClose={handleCloseDeleteItemModal}
                handleSubmit={handleConfirmDeleteItem}
                loading={deleteItemApi.loading}
                open={true}
            />
        }
        {
            (deleteItems && !!Object.keys(selectedItems).length) && <ConfirmationModal
                title={"Delete Network Integrations"}
                description={
                    <>
                        Are you sure you want to delete <strong>{Object.keys(selectedItems).length}</strong> network integration(s)?
                    </>
                }
                handleClose={handleCloseDeleteMultipleItemsModal}
                handleSubmit={handleDeleteMultipleItems}
                loading={deleteItemsApi.loading}
                open={true}
            />

        }
    </>
};

export default NetworkIntegrations