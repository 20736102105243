// function to return the currency symbol based on the currency code it should take a number and return a string placing the currency appropriately for dollar in front and for euro at the end with an algorithm

const currencySymbol = '$'

const symbolsAtEnd = ['€'];
const symbolsInFront = ['$', '£'];


export const formatCurrency = (amount, includeDecimals = true) => {
    // Format the amount to 2 decimal places and add commas at the thousands
    const formattedAmount = Math.abs(amount)
        .toLocaleString(
            undefined,
            {
                minimumFractionDigits: includeDecimals ? 2 : 0,
                maximumFractionDigits: includeDecimals ? 2 : 0
            }
        );

    // Determine the position of the currency symbol and return the formatted string
    if (symbolsAtEnd.includes(currencySymbol)) {
        return amount < 0
            ? `-${formattedAmount}${currencySymbol}`
            : `${formattedAmount}${currencySymbol}`;
    } else if (symbolsInFront.includes(currencySymbol)) {
        return amount < 0
            ? `-${currencySymbol}${formattedAmount}`
            : `${currencySymbol}${formattedAmount}`;
    }
    // If the currency symbol doesn't match known patterns, return only the formatted amount
    return amount < 0 ? `-${formattedAmount}` : formattedAmount;
};