import React, {useEffect, useState} from 'react';
import {Box, IconButton, Tooltip, useTheme} from "@mui/material";
import CustomAppBar from "../components/NavigationBar/CustomAppBar";
import EnhancedTable from "../components/Table/EnhancedTable";
import useTable from "../hooks/useTable";
import {SmartToy, Visibility} from "@mui/icons-material";
import Filters from "../components/Filters/Filters";
import {filtersElementsTypes} from "../utils/constants";
import {networkIntegrationService} from "../api/services/networkIntegrationService";
import {categoryService} from "../api/services/categoryService";
import {offerService} from "../api/services/offerService";
import {partnerService} from "../api/services/partnerService";
import {mailingListService} from "../api/services/mailingListService";
import {verticalService} from "../api/services/verticalService";
import {campaignReportingService} from "../api/services/campaignReportingService";
import ContentReportingPreviewModal from "../components/ContentReporting/ContentReportingPreviewModal";
import ExternalLinkButton from "../components/Campaign/ExternalLinkButton";
import CampaignReportingDataAlertChip from "../components/CampaignReporting/CampaignReportingDataAlertChip";
import {espIntegrationService} from "../api/services/espIntegrationService";
import {brandService} from "../api/services/brandService";
import useUrlParams from "../hooks/useUrlParams";
import useData from "../hooks/useData";
import dayjs from "dayjs";
import {mailingListGroupService} from "../api/services/mailingListGroupService";

const CampaignReporting = () => {
    const theme = useTheme()
    const urlParams = useUrlParams()

    const [modalOpen, setModalOpen] = useState(null)

    const handleModalClose = () => {
        setModalOpen(null)
    }

    const handleModalOpen = (content_id) => {
        setModalOpen(content_id)
    }

    const {
        items,
        totals,
        itemsPagination,
        itemsSort,
        itemsFilters,
        fetchItemsApi,
    } = useData(
        campaignReportingService.getItems,
        {
            common: {
                type: filtersElementsTypes.TEXT,
            },
            send_datetime_start: {
                type: filtersElementsTypes.DATE,
                label: "Send Date Start",
                columns: 6,
                disableFuture: true,
                defaultValue: dayjs().startOf('month')
            },
            send_datetime_end: {
                type: filtersElementsTypes.DATE,
                label: "Send Date End",
                columns: 6,
                disableFuture: true
            },
            mailing_list_group_id: {
                label: "List Groups",
                apiCall: mailingListGroupService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            mailing_list_id: {
                label: "Lists",
                apiCall: mailingListService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            subject_line: {
                type: filtersElementsTypes.TEXT,
                label: "Subject Line",
            },
            from_name: {
                type: filtersElementsTypes.TEXT,
                label: "From Name",
            },
            offer_id: {
                label: "Offers",
                apiCall: offerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            vertical_id: {
                label: "Verticals",
                apiCall: verticalService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            category_id: {
                label: "Categories",
                apiCall: categoryService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            brand_id: {
                label: "From Email (Brands)",
                apiCall: brandService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
                searchField: "from_email",
                setOptionLabel: (item) => item.from_email,
            },
            partner_id: {
                label: "Partners",
                apiCall: partnerService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            esp_integration_id: {
                label: "ESP Integrations",
                apiCall: espIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            network_integration_id: {
                label: "Network Integrations",
                apiCall: networkIntegrationService.getItems,
                type: filtersElementsTypes.MULTISELECT_API,
            },
            creation_method: {
                type: filtersElementsTypes.SELECT,
                options: [
                    {key: "Manual", value: 1},
                    {key: "Automatic", value: 2}
                ],
                label: "Created By"
            },
            min_sends: {
                type: filtersElementsTypes.NUMBER,
                label: "Min Sends",
                columns: 6
            },
            min_clicks: {
                type: filtersElementsTypes.NUMBER,
                label: "Min Clicks",
                columns: 6
            },
            send_time_start: {
                type: filtersElementsTypes.TIME,
                label: "Send Time Start",
                columns: 6
            },
            send_time_end: {
                type: filtersElementsTypes.TIME,
                label: "Send Time End",
                columns: 6
            }
        },
        {},
                {
            sortBy: "send_time",
            sortOrder: "asc"
        },
        urlParams.queryParams
    )

    useEffect(() => {
        urlParams.updateUrlParams(
            itemsFilters,
            itemsPagination,
            itemsSort,
        )
    }, [
        itemsPagination.values,
        itemsFilters.values,
        itemsSort.values,
    ])

    const headCells = [
        {
            id: 'content_id',
            numeric: false,
            disablePadding: true,
            width: "48px",
            element: (item) => (
                <IconButton aria-label="preview" onClick={() => handleModalOpen(item.content_id)}>
                    <Visibility/>
                </IconButton>
            )
        },
        {
            element: (item) => <ExternalLinkButton campaign_data={{
                esp_integration: {
                    esp_id: item.esp_id,
                    maropost_external_account_id: item.maropost_external_account_id
                },
                external_id: item.external_id
            }}/>,
            width: theme.customTokens.defaultTableIconWidth
        },
        {id: 'campaign_id', numeric: false, disablePadding: false, label: 'ID', width: "100px", highlight: true},
        {
            width: "40px",
            element: (item, combined) => {
                if (item.creation_method === 2)
                    return <Tooltip title={"Campaign Created Automatically"} arrow>
                        <SmartToy color={"disabled"}/>
                    </Tooltip>
                else
                    return null
            }
        },
        {
            id: 'mailing_list_name',
            numeric: false,
            disablePadding: false,
            label: 'List',
            width: "400px"
        },
        {id: 'send_time', numeric: false, disablePadding: true, datetime: true, label: 'Sent At', width: "120px"},
        {id: 'offer_name', numeric: false, disablePadding: false, label: 'Offer', width: "250px"},
        {
            id: 'clicks',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Clicks',
            width: "100px",
            totals: totals.total_clicks
        },
        {
            id: 'revenue',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Revenue',
            width: "190px",
            float: true,
            trailingSign: "$",
            totals: totals.total_revenue
        },
        {
            id: 'sends', numeric: true,
            align: "right", disablePadding: false, label: 'Sends', width: "150px",
            totals: totals.total_sends
        },
        {
            id: 'delivered', numeric: true,
            align: "right", disablePadding: false, label: 'Delivered', width: "120px",
            totals: totals.total_delivered
        },
        {
            id: 'delivery_rate',
            element: (item) => <CampaignReportingDataAlertChip
                campaignData={item}
                targetFieldName={"delivery_rate"}
                controlFieldName={"delivered"}
                isGreen={(val) => val >= 90}
                isYellow={(val) => val >= 80}
                getText={(val) => parseFloat(val).toFixed(2) + "%"}

            />,
            label: "Delivery Rate",
            totals: totals.delivery_rate,
            disablePadding: true,
            numeric: true,
            align: "right",
            float: true,
            trailingSign: "%",
            width: "140px",
        },
        {
            id: 'unique_opens', numeric: true,
            align: "right", disablePadding: false, label: 'Unique Opens', width: "130px",
            totals: totals.total_unique_opens
        },
        {
            id: 'unique_open_rate',
            element: (item) => <CampaignReportingDataAlertChip
                campaignData={item}
                targetFieldName={"unique_open_rate"}
                controlFieldName={"unique_opens"}
                isGreen={(val) => val >= 30}
                isYellow={(val) => val >= 20}
                getText={(val) => parseFloat(val).toFixed(2) + "%"}
            />,
            label: "Unique Open Rate",
            disablePadding: true,
            numeric: true,
            align: "right",
            float: true,
            trailingSign: "%",
            width: "170px",
            totals: totals.unique_open_rate
        },
        {
            id: 'opens', numeric: true,
            align: "right", disablePadding: false, label: 'Opens', width: "150px",
            totals: totals.total_opens
        },
        {
            id: 'open_rate',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Open Rate',
            width: "150px",
            float: true,
            trailingSign: "%",
            totals: totals.open_rate
        },
        {
            id: 'unique_clicks', numeric: true,
            align: "right", disablePadding: false, label: 'Unique Clicks', width: "150px",
            totals: totals.total_unique_clicks
        },
        {
            id: 'unique_click_rate',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Unique Click Rate',
            width: "170px",
            float: true,
            trailingSign: "%",
            totals: totals.unique_click_rate
        },
        {
            id: 'total_clicks', numeric: true,
            align: "right", disablePadding: false, label: 'Total Clicks', width: "120px",
            totals: totals.total_clicks_sum
        },
        {
            id: 'total_click_rate',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Total Click Rate',
            width: "150px",
            float: true,
            trailingSign: "%",
            totals: totals.total_click_rate
        },
        {
            id: 'complaints', numeric: true,
            align: "right", disablePadding: false, label: 'Complaints', width: "150px",
            totals: totals.total_complaints
        },
        {
            id: 'complaints_rate',
            element: (item) => <CampaignReportingDataAlertChip
                campaignData={item}
                targetFieldName={"complaints_rate"}
                controlFieldName={"complaints"}
                isGreen={(val) => val < 0.1}
                isYellow={(val) => val < 0.25}
                getText={(val) => parseFloat(val).toFixed(2) + "%"}
            />,
            label: "Complaints Rate",
            disablePadding: true,
            numeric: true,
            align: "right",
            float: true,
            trailingSign: "%",
            width: "170px",
            totals: totals.complaints_rate
        },
        {
            id: 'unsubscribes', numeric: true,
            align: "right", disablePadding: false, label: 'Unsubscribes', width: "150px",
            totals: totals.total_unsubscribes
        },
        {
            id: 'unsubscribes_rate',
            numeric: true,
            align: "right",
            disablePadding: false,
            label: 'Unsubscribes Rate',
            width: "170px",
            float: true,
            trailingSign: "%",
            totals: totals.unsubscribes_rate
        },
        {
            id: 'spam_reports', numeric: true,
            align: "right", disablePadding: false, label: 'Spam Reports', width: "150px",
            totals: totals.total_spam_reports
        },
        {id: 'esp_integration_name', numeric: false, disablePadding: false, label: 'ESP', width: "120px"},
        {id: 'partner_name', numeric: false, disablePadding: false, label: 'Partner', width: "150px"},
        {id: 'from_email', numeric: false, disablePadding: false, label: 'Brand', width: "300px"},
        {id: 'network_integration_name', numeric: false, disablePadding: false, label: 'Network Integration', width: "200px"},
    ];

    const table = useTable(items, itemsPagination)

    return <>
        <Box>
            <CustomAppBar/>
            <Filters filters={itemsFilters}/>
            <EnhancedTable
                table={table}
                disableSelecting={true}
                headCells={headCells}
                data={{
                    items: items,
                    sort: itemsSort,
                    pagination: itemsPagination,
                    api: fetchItemsApi
                }}
                totals={totals}
                searchTerm={itemsFilters.values[itemsFilters.primaryFilter]}
            />
        </Box>
        <ContentReportingPreviewModal
            open={modalOpen}
            handleClose={handleModalClose}
        />
    </>
}


export default CampaignReporting