import {Controller} from "react-hook-form";
import {MenuItem, TextField} from "@mui/material";
import React from "react";
import CustomAutocompleteSelectField from "./CustomAutocompleteSelectField";
import CustomAPIAutocompleteSelectField from "./CustomAPIAutocompleteSelectField";

const ControlledAPIAutocompleteSelectField = (
    {
        control,
        name,
        label,
        apiCall,
        apiFilters,
        sortBy,
        sortOrder,
        setOptionLabel,
        searchField,
        multiple = false,
        ignoreInput = false,
        additionalOptionsFields,
        disableCloseOnSelect,
    }) => {
    return <Controller
        name={name}
        control={control}
        render={({field: {onChange, value}, fieldState: {error}}) => {
            console.debug(value)
            return <CustomAPIAutocompleteSelectField
                label={label}
                onChange={onChange}
                value={value}
                error={error}
                multiple={multiple}
                apiCall={apiCall}
                setOptionLabel={setOptionLabel}
                searchField={searchField}
                apiFilters={apiFilters}
                additionalOptionsFields={additionalOptionsFields}
                sortBy={sortBy}
                sortOrder={sortOrder}
                ignoreInput={ignoreInput}
                disableCloseOnSelect={disableCloseOnSelect}
            />
        }}
    />
}

export default ControlledAPIAutocompleteSelectField