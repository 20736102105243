import React, {useMemo, useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {networkIntegrationService} from "../../../api/services/networkIntegrationService";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import {option} from "../../../utils/selectFIeldHelpers";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomAutocompleteSelectField from "../../CustomAutocompleteSelectField";
import {EVERFLOW, NATIVEPATH, ULTRACART} from "../../../utils/constants";
import useApi from "../../../hooks/useApi";
import {networkService} from "../../../api/services/networkService";
import CustomAPIAutocompleteSelectField from "../../CustomAPIAutocompleteSelectField";



const AddNetworkIntegrationModal = ({setSnack, open, handleClose, fetchNetworkIntegrations}) => {

    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [loading, setLoading] = useState(false)

    const networksWithoutAccount = [
        EVERFLOW,
        NATIVEPATH
    ]

    const networksWithAdditionalField1 = [
        ULTRACART,
    ]

    const additionalField1Labels = {
        [ULTRACART]: {
            label: "Login URL",
            helperText: "URL must include merchantId in parameters"
        }
    }

    const selectedNetworkIsWithAccount = !!selectedNetwork && !networksWithoutAccount.includes(selectedNetwork.value)
    const selectedNetworkIsWithAdditionalField1 = !!selectedNetwork && networksWithAdditionalField1.includes(selectedNetwork.value)

    const validationSchema = useMemo(() => {
        const schema = {
            api_key: yup.string().required('API key is required'),
            name: yup.string().required('Name is required'),
            external_account: yup.string(),
            additional_field_1: yup.string(),
            status: yup.bool()
        };

        if (selectedNetworkIsWithAccount) {
            schema.external_account = schema.external_account.required('External account is required').min(1, 'Required');
        }

        if (selectedNetworkIsWithAdditionalField1){
            schema.additional_field_1 = yup.string().required('UltraCart Link required')
        }

        return yup.object().shape(schema);
    }, [selectedNetwork]); // Depend on the boolean check for whether the selected network has an account

    // useForm hook with dynamic resolver
    const {control, handleSubmit, reset} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            api_key: "",
            name: "",
            external_account: "",
            additional_field_1: "",
            status: true
        }
    });

    const onChangeSelectedNetwork = (newNetwork) => {
        setSelectedNetwork(newNetwork)
    }

    const onSuccess = (data) => {
        setLoading(true)

        networkIntegrationService.createItem(
            selectedNetwork.value,
            data.api_key,
            data.name,
            selectedNetworkIsWithAccount ? data.external_account : "",
            selectedNetworkIsWithAdditionalField1 ? data.additional_field_1 : ""
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                reset()
                fetchNetworkIntegrations()
            })
            .catch((error) => {
                console.debug(error)
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create Network Integration"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                        <CustomAPIAutocompleteSelectField
                            value={selectedNetwork}
                            onChange={(value) => onChangeSelectedNetwork(value)}
                            label={"Network"}
                            multiple={false}
                            apiCall={networkService.getItems}
                            ignoreInput={true}
                            disableCloseOnSelect={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledTextField control={control} name={"name"} label={"Name"}/>
                    </Grid>
                    {
                        !!selectedNetwork && (
                            <>
                                {
                                    selectedNetworkIsWithAccount &&
                                    <Grid item xs={4}>
                                        <ControlledTextField control={control} name={"external_account"}
                                                             label={"Account/Affiliate ID"}/>
                                    </Grid>
                                }
                                <Grid item xs={selectedNetworkIsWithAccount ? 8 : 12}>
                                    <ControlledTextField control={control} name={"api_key"} label={"API Key"}/>
                                </Grid>
                                {
                                    selectedNetworkIsWithAdditionalField1 &&
                                    <Grid item xs={12}>
                                        <ControlledTextField control={control} name={"additional_field_1"}
                                                             label={additionalField1Labels[selectedNetwork.value].label}
                                            helperText={additionalField1Labels[selectedNetwork.value].helperText}
                                        />
                                    </Grid>
                                }
                            </>
                        )
                    }

                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                {/*<Grid container spacing={2} sx={{mt: 3}}>*/}
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        disabled={!selectedNetwork}
                        text={"Create"}
                        loading={loading}
                    />
                </Grid>

                {/*</Grid>*/}
            </CustomModalFooter>


            {/*</Container>*/}
        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddNetworkIntegrationModal);