const scrollBarStyles = {
    '&::-webkit-scrollbar': {
        width: '8px',
        height: "8px",
        backgroundColor: '#e0e0e0',
    },
    '& *::-webkit-scrollbar': {
        width: '8px',
        height: "8px",
        borderRadius: '10px',
        backgroundColor: 'rgba(224,224,224,0)',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: '#574FDF',
        // minHeight: '10px',
        // border: '2px solid #e0e0e0',
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
        backgroundColor: '#5a52cc',
    },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
        backgroundColor: '#5a52cc',
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#6056da',
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#ffffff',
    },
}

const stringifyCss = (styles) => {
    let cssString = '';

    // Iterate over each key in the style object
    for (const selector in styles) {
        cssString += `${selector} {\n`; // Start selector
        const properties = styles[selector];
        for (const prop in properties) {
            const value = properties[prop];
            const cssPropName = prop.replace(/([A-Z])/g, '-$1').toLowerCase(); // Convert camelCase to kebab-case
            cssString += `  ${cssPropName}: ${value};\n`; // Append property: value;
        }
        cssString += `}\n`; // Close selector
    }

    return cssString;
};

export const generateSrcDocWithStyles = (code) => {
    const css = `
        body {
            overflow: auto;
            /* Scrollbar styles */
        }
        ${stringifyCss(scrollBarStyles)}
    `;
    return `<html><head><style>${css}</style></head><body>${code}</body></html>`;
}

const getDesignTokens = (mode) => {
    const normalBorderRadius = 4
    return {
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    primary: {
                        contrastText: "rgba(255,255,255,0.87)",
                        dark: "#574FDF",
                        light: "#EBF0FF",
                        main: "#746cff"
                    },
                }
                : {
                    background: {
                        default: "#ffffff",
                        paper: "#fafafa",
                    },
                    text: {
                        primary: '#fff',
                        secondary: "#858585",
                    },
                    primary: {
                        contrastText: "rgba(0, 0, 0, 0.87)",
                        dark: "#746cff",
                        light: "#EBF0FF",
                        main: "#746cff"
                    },
                    secondary: {
                        contrastText: "rgba(0, 0, 0, 0.87)",
                        dark: "#94151c",
                        light: "#e3f2fd",
                        main: "#940107"
                    }
                }),
        },

        customTokens: {
            defaultRowHeight: 54,
            defaultTableIconWidth: 52,
            tableHighlightColor: "#F9FBFF"
        },
        typography: {
            button: {
                textTransform: 'none',
                fontWeight: 600
            },
            "fontFamily": `"Inter"`,
            "fontSize": 14,
            "fontWeightLight": 300,
            "fontWeightRegular": 400,
            "fontWeightMedium": 500,
            "title": {
                fontFamily: "Inter",
                fontSize: 24,
                fontWeight: 600,
                lineHeight: "36px"
            },
            "alerts": {
                fontSize: 16,
                fontWeight: 600,
                lineHeight: "24px"
            },
            "body2": {
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "24px",
            },
            "defaultSearch": {
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "24px",
                fontStyle: "italic"
            },
            "tableHighlight": {
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "21px"
            },
            "menuItem": {
                fontSize: 14,
                fontWeight: 500,
                lineHeight: "21px"
            },
            "table": {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "21px"
            },
            "placeholder": {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
                fontStyle: "italic"
            },
            "label": {
                fontSize: 12,
                fontWeight: 400,
                lineHeight: "18px",
                fontStyle: "italic"
            },
            "cardTitle": {
                fontSize: 14,
                fontWeight: 600,
                lineHeight: "18px"
            },
            "cardValue": {
                fontSize: 32,
                fontWeight: 600,
                lineHeight: "42px"
            },
            "cardTitleSmall": {
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "18px"
            },
            "cardValueSmall": {
                fontSize: 24,
                fontWeight: 400,
                lineHeight: "24px"
            }
        },
        shape: {
            borderRadius: 24
        },
        components: {
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        py: 0,
                        minHeight: 28,
                    },
                },
            },

            MuiPopover: {
                styleOverrides: {
                    paper: {
                        borderRadius: normalBorderRadius
                    }
                }
            },
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        borderRadius: normalBorderRadius
                    },
                    // inputRoot: {
                    //     overflowY: "scroll",
                    //     overflowX: "hidden",
                    //     // flexWrap: 'nowrap',
                    //     maxHeight: "100px"
                    //     // paddingRight: "10px!important"
                    // },
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        height: 30,
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        borderRadius: normalBorderRadius, // Set the border radius for the paper of MuiMenu
                        // You can add other styles here as needed
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        ...scrollBarStyles
                    },
                },
            },
            // MuiButton: {
            //     styleOverrides: {
            //         // Name of the slot
            //         root: {
            //             borderRadius: 24,
            //             height: 50
            //         },
            //     },
            // },

        },
    }
}

export default getDesignTokens;