import React, {useEffect, useState} from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {Box, Grid, Paper, Typography} from "@mui/material";
import ChartCard from "./ChartCard";
import {formatCurrency} from "../../utils/curencyUtils";
import {axisClasses} from "@mui/x-charts/ChartsAxis";
import {useDropdownSelect} from "./useDropdownSelect";
import useApi from "../../hooks/useApi";
import {dashboardService} from "../../api/services/dashboardService";
import CenteredLoader from "../Loaders/CenteredLoader";
import { ResponsiveContainer } from "recharts";


const periods = [
    {label: '7 days', key: 7},
    {label: '30 days', key: 30},
    {label: '60 days', key: 60},
    {label: '90 days', key: 90},
]

const DailyRevenueChartCard = ({label = 'Daily Revenue'}) => {

    const [selectedDays, periodSelector] = useDropdownSelect(
        periods, "Period"
    );

    const [data, setData] = useState([])

    const {loading, makeRequest} = useApi(dashboardService.getDailyRevenue, true)

    useEffect(() => {
        makeRequest(
            selectedDays,
            (response) => {
                setData(response.data.reverse())
            }
        )
    }, [selectedDays]);


    return (
        <ChartCard label={'Daily Revenue'}
                   metricsSelector={periodSelector}
                   infoTooltipText={"Displays the daily revenue over the selected period. This data reflects the total revenue earned each day"}>
            {loading ? <CenteredLoader height={400}/> : (
                <Box sx={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            grid={{ vertical: true, horizontal: true }}
                            dataset={data}
                            xAxis={[{
                                dataKey: 'date',
                                scaleType: 'band',
                            }]}
                            yAxis={[{
                                scaleType: 'linear',
                                label: 'Revenue',
                                min: 0,
                                valueFormatter: (value) => formatCurrency(value, false),
                            }]}
                            series={[
                                { dataKey: 'revenue', label: 'Revenue', valueFormatter: (value) => formatCurrency(value, false) },
                                { dataKey: 'avg', label: `Avg. Revenue`, showMark: false, valueFormatter: (value) => formatCurrency(value, false) }
                            ]}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translate(-45px, 0)' },
                                [`.${axisClasses.right} .${axisClasses.label}`]: { transform: 'translate(30px, 0)' },
                            }}
                            slotProps={{ legend: { itemGap: 20 } }}
                            margin={{ left: 90, right: 0 }}
                        />
                    </ResponsiveContainer>
                </Box>
            )}
        </ChartCard>
    );
};

export default React.memo(DailyRevenueChartCard);