import React from 'react';
import {Box, Paper, Skeleton, Typography, useTheme} from "@mui/material";
import {formatCurrency} from "../../utils/curencyUtils";
import Info from '../Info';

const StatsCard = ({
                       title,
                       value,
                       averageValue = undefined,
                       previousValue = undefined,
                       fullSize = false,
                       formatFunction = undefined,
                       inverseRates = 1,
                       complexView = false,
                       loading = true,
                       infoTooltipText= '',
                       tooltip_top = 10,
                       tooltip_right = 0,
                   }) => {
    const theme = useTheme();

    let averageValueRelative = averageValue ? ((value - averageValue) / averageValue * 100).toFixed(2) : undefined;
    let previousValueRelative = previousValue ? ((value - previousValue) / previousValue * 100).toFixed(2) : undefined;

    return (
        <Paper sx={{
            height: 140,
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
        }}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            {/*Row 1*/}
            <Typography variant="cardTitle">
                {title}
            </Typography>
            {loading ? <Skeleton variant="text" width={95} height={45}/> :
                <Typography variant="cardValue" sx={{color: theme.palette.primary.main}}>
                    {formatFunction ? formatFunction(value) : value}
                </Typography>
            }

            {
                complexView &&
                <Box sx={{display: 'flex', justifyContent: 'space-around', width: '100%', mt: 0}}>
                    {/*Col 1*/}
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant="cardTitleSmall">
                            Avg.
                        </Typography>
                        {loading ? <Skeleton variant="text" width={65} height={35}/> :
                        <Typography variant="cardValueSmall" sx={{color: theme.palette.grey[500]}}>
                            {formatFunction ? formatFunction(averageValue) : averageValue}
                        </Typography>
                        }
                        <Typography
                            variant="label"
                            sx={{
                                color: averageValueRelative * inverseRates > 0 ? 'green' : averageValueRelative * inverseRates < 0 ? 'red' : 'grey',
                            }}
                        >
                            {averageValueRelative ? (averageValueRelative > 0 ? '+' : '') + averageValueRelative + '%' : ''}
                        </Typography>
                    </Box>
                    {/*Col 2*/}
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Typography variant="cardTitleSmall">
                            Before
                        </Typography>
                        {loading ? <Skeleton variant="text" width={65} height={35}/> :
                        <Typography variant="cardValueSmall" sx={{color: theme.palette.grey[500]}}>
                            {formatFunction ? formatFunction(previousValue) : previousValue}
                        </Typography>
                        }
                        <Typography
                            variant="label"
                            sx={{
                                color: previousValueRelative * inverseRates > 0 ? 'green' : previousValueRelative * inverseRates < 0 ? 'red' : 'grey',
                            }}
                        >
                            {previousValueRelative ? (previousValueRelative > 0 ? '+' : '') + previousValueRelative + '%' : ''}
                        </Typography>
                    </Box>
                </Box>
            }
            {
                infoTooltipText && (
                <Box sx={{ position: 'absolute', top: tooltip_top, right: tooltip_right }}>
                  <Info tooltipText={infoTooltipText} />
                </Box>
              )}
        </Paper>
    );
};

export default StatsCard;