import React from 'react';
import {Box, Grid, Paper, Typography, useMediaQuery, useTheme} from "@mui/material";
import Info from '../Info';

const ChartCard = ({
  label,
  metricsSelector = null,
  periodSelector = null,
  infoTooltipText = '',
    tooltip_top = 0,
    tooltip_right = 0,
  ...props
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid item xs={12} lg={6}>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    position: 'relative'
                }}
            >
                <Grid container spacing={2}>
                    <Grid item
                          xs={12}
                          md={metricsSelector && periodSelector ? 12 : 6}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                flex: 1, // Takes available space to center the label
                                textAlign: 'left', // Adjust alignment
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    {periodSelector && (
                        <Grid item xs={12} md={6}>
                            <Box sx={{}}>{periodSelector}</Box>
                        </Grid>
                    )}
                    {metricsSelector && (
                        <Grid item xs={12} md={6}>
                            <Box sx={{}}>{metricsSelector}</Box>
                        </Grid>
                    )}
                </Grid>

                <Box sx={{ width: '100%', maxWidth: isSmallScreen ? 400 : isMediumScreen ? 600 : '90vw' }}>
                    {props.children}
                </Box>
                 {infoTooltipText && (
                  <Box sx={{ position: 'absolute', top: tooltip_top, right: tooltip_right}}>
                    <Info tooltipText={infoTooltipText} />
                  </Box>
                )}
            </Paper>
        </Grid>
    );
};

export default React.memo(ChartCard);