import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import CustomAutocompleteSelectField from "../CustomAutocompleteSelectField";
import CustomDateTimePicker from "../CustomDateTimePicker";
import ManualScheduleHelperModalText from "./ManualScheduleHelperModalText";
import ManualScheduleHelperModalContent from "./ManualScheduleHelperModalContent";
import CustomAPIAutocompleteSelectField from "../CustomAPIAutocompleteSelectField";
import {contentService} from "../../api/services/contentService";
import {offerService} from "../../api/services/offerService";

const ManualScheduleHelperModalSettings = (
    {
        selectedMailingLists,
        selectedSettings,
        handleChangeSelectedSettings,
        description
    }) => {

    const [selectedOffer, setSelectedOffer] = useState(null)

    const handleChangeSelectedOffer = (newOffer) => {
        setSelectedOffer(newOffer)
    }

    const configs = new Set()

    Object.entries(selectedMailingLists).forEach(([listId, list]) => {
        // console.debug(list, listId)
        list.configs.forEach((config) => {
            configs.add(config.name)
        })
    })

    const configsOptions = Array.from(configs).map((configName, index) => {
        return {
            key: configName,
            value: index
        }
    })
    console.debug(selectedOffer)
    console.debug(configsOptions)
    return (

        <ManualScheduleHelperModalContent>
            <Grid item xs={12}>
                <ManualScheduleHelperModalText helperText={"Pick Campaigns Send Settings"}/>
            </Grid>
            <Grid item xs={12}>
                {description}
            </Grid>
            <Grid item xs={12}>
                <Box sx={{height: 380 + 64, mb: 2}}>
                    <Grid container>
                        {/*<Grid item xs={3}>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <CustomAPIAutocompleteSelectField
                                multiple={false}
                                label={"Offer"}
                                apiCall={offerService.getItems}
                                onChange={handleChangeSelectedOffer}
                                apiFilters={{
                                    is_active: true,
                                    available: true
                                }}
                                ignoreInput={true}
                                disableCloseOnSelect={false}
                            />
                        </Grid>
                        {
                            selectedOffer &&
                            <Grid item xs={12}>
                                <CustomAPIAutocompleteSelectField
                                    multiple={false}
                                    label={"Content"}
                                    apiCall={contentService.getItems}
                                    apiFilters={{
                                        offer_id: [selectedOffer],
                                        is_active: true
                                    }}
                                    onChange={(content) => handleChangeSelectedSettings({content: content})}
                                    additionalOptionsFields={["offer.id", "offer.name"]}
                                    ignoreInput={true}
                                    disableCloseOnSelect={false}
                                />
                            </Grid>
                        }


                        {/*<Grid item xs={3}>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={3}>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <CustomAutocompleteSelectField
                                multiple={false}
                                value={selectedSettings.config}
                                options={configsOptions}
                                label={"Config"}
                                onChange={(newVal) => handleChangeSelectedSettings({config: newVal})}
                            />
                        </Grid>

                        {/*<Grid item xs={3}>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={3}>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <CustomDateTimePicker
                                value={selectedSettings.sendTime}
                                label={"Send Time"}
                                onChange={(newVal) => handleChangeSelectedSettings({sendTime: newVal})}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>


        </ManualScheduleHelperModalContent>
    );
};

export default ManualScheduleHelperModalSettings;