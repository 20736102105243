import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
        }

        if (!!args.created_datetime_start && args.created_datetime_start !== "")
            data.created_datetime_start = args.created_datetime_start.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (!!args.created_datetime_end && args.created_datetime_end !== "")
            data.created_datetime_end = args.created_datetime_end.format('YYYY-MM-DDTHH:mm:ss.SSS')


        if (args.campaign_id) {
            data.campaign_id = args.campaign_id.map(item => item.value)
        }

        if (args.mailing_list_id) {
            data.mailing_list_id = args.mailing_list_id.map(item => item.value)
        }

        // if (args.mailing_list_group_id) {
        //     data.mailing_list_group_id = args.mailing_list_group_id.map(item => item.value)
        // }

        console.debug('data', data)

            apiClient.get(endpoints.campaignReportingUngrouped, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }})
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })


    })
}

export const campaignReportingUngroupedService = {
    getItems,
}