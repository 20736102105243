import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from 'qs';

const getDailyRevenue = (
    period
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            period,
        }

        apiClient.get(endpoints.dashboard + '/daily-revenue', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getDailyEspStats = (
    period
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            period,
        }

        apiClient.get(endpoints.dashboard + '/daily-esp-stats', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getDailyEspStatsRates = (
    period
) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            period,
        }

        apiClient.get(endpoints.dashboard + '/daily-esp-stats-rates', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getMonthlyRevenue = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/monthly-revenue', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getTodayRevenue = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/current-revenue', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getYesterdayRevenue = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/yesterday-revenue', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getWeekRevenue = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/week-revenue', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getMonthRevenue = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/month-revenue', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getYesterdayUnsubscribes = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/yesterday-unsubscribes', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getWeekUnsubscribes = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/week-unsubscribes', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getMonthUnsubscribes = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/month-unsubscribes', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getWeightedPPM = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/weighted-ppm', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getBestOffers = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/best-offers', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getYesterdayContacts = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/yesterday-contacts', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getWeekContacts = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/week-contacts', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

const getMonthContacts = () => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {

        }

        apiClient.get(endpoints.dashboard + '/month-contacts', {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'});
            }
        }).then((response) => {
            resolve(response.data);
        }).catch((errorResponse) => {
            reject(errorResponse.data);
        });
    });
};

export const dashboardService = {
    getDailyRevenue,
    getMonthlyRevenue,
    getTodayRevenue,
    getYesterdayRevenue,
    getWeekRevenue,
    getMonthRevenue,
    getYesterdayUnsubscribes,
    getWeekUnsubscribes,
    getMonthUnsubscribes,
    getDailyEspStats,
    getDailyEspStatsRates,
    getWeightedPPM,
    getBestOffers,
    getYesterdayContacts,
    getWeekContacts,
    getMonthContacts
};
