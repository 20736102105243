import React, { forwardRef } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {Box, Tooltip, useTheme} from "@mui/material";

const Info = ({ size = 16,
                             color = undefined,
                             tooltipText = "More info",
                             ...otherProps }) => {
    const theme = useTheme()
    if (color === undefined)
        color = theme.palette.primary.main
    return (
        <Tooltip title={tooltipText} arrow>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: size,
                    height: size,
                    color: color,
                    cursor: 'pointer',
                }}
                {...otherProps}
            >
                <InfoIcon sx={{fontSize: size, color: color}}/>
            </Box>
        </Tooltip>
    )
};

export default Info;