import React, {useEffect, useState} from 'react';
import {Box, Grid} from "@mui/material";
import StatsCard from "./StatsCard";
import {formatCurrency} from "../../utils/curencyUtils";
import useApi from "../../hooks/useApi";
import {dashboardService} from "../../api/services/dashboardService";


const RevenueStatsCards = () => {

    const [todayRevenue, setTodayRevenue] = useState({})
    const [yesterdayRevenue, setYesterdayRevenue] = useState({})
    const [weekRevenue, setWeekRevenue] = useState({})
    const [monthRevenue, setMonthRevenue] = useState({})
    const [yesterdayUnsubscribes, setYesterdayUnsubscribes] = useState({})
    const [weekUnsubscribes, setWeekUnsubscribes] = useState({})
    const [monthUnsubscribes, setMonthUnsubscribes] = useState({})
    const [weightedPPM, setWeightedPPM] = useState({})
    const [yesterdayContacts, setYesterdayContacts] = useState({})
    const [weekContacts, setWeekContacts] = useState({})
    const [monthContacts, setMonthContacts] = useState({})

    const {loading: loadingTodayRevenue, makeRequest: todayRevenueRequest} = useApi(
        dashboardService.getTodayRevenue, true
    )

    const {loading: loadingYesterdayRevenue, makeRequest: yesterdayRevenueRequest} = useApi(
        dashboardService.getYesterdayRevenue, true
    )

    const {loading: loadingWeekRevenue, makeRequest: weekRevenueRequest} = useApi(
        dashboardService.getWeekRevenue, true
    )

    const {loading: loadingMonthRevenue, makeRequest: monthRevenueRequest} = useApi(
        dashboardService.getMonthRevenue, true
    )

    const {loading: loadingYesterdayUnsubscribes, makeRequest: yesterdayUnsubscribesRequest} = useApi(
        dashboardService.getYesterdayUnsubscribes, true
    )

    const {loading: loadingWeekUnsubscribes, makeRequest: weekUnsubscribesRequest} = useApi(
        dashboardService.getWeekUnsubscribes, true
    )

    const {loading: loadingMonthUnsubscribes, makeRequest: monthUnsubscribesRequest} = useApi(
        dashboardService.getMonthUnsubscribes, true
    )

    const {loading: loadingWeightedPPM, makeRequest: weightedPPMRequest} = useApi(
        dashboardService.getWeightedPPM, true
    )

    const {loading: loadingYesterdayContacts, makeRequest: yesterdayContactsRequest} = useApi(
        dashboardService.getYesterdayContacts, true
    )

    const {loading: loadingWeekContacts, makeRequest: weekContactsRequest} = useApi(
        dashboardService.getWeekContacts, true
    )

    const {loading: loadingMonthContacts, makeRequest: monthContactsRequest} = useApi(
        dashboardService.getMonthContacts, true
    )

    useEffect(() => {
        todayRevenueRequest(
            null,
            (response) => {
                setTodayRevenue(response.data)
            }
        )
        yesterdayRevenueRequest(
            null,
            (response) => {
                setYesterdayRevenue(response.data)
            }
        )
        weekRevenueRequest(
            null,
            (response) => {
                setWeekRevenue(response.data)
            }
        )
        monthRevenueRequest(
            null,
            (response) => {
                setMonthRevenue(response.data)
            }
        )
        yesterdayUnsubscribesRequest(
            null,
            (response) => {
                setYesterdayUnsubscribes(response.data)
            }
        )
        weekUnsubscribesRequest(
            null,
            (response) => {
                setWeekUnsubscribes(response.data)
            }
        )
        monthUnsubscribesRequest(
            null,
            (response) => {
                setMonthUnsubscribes(response.data)
            }
        )
        weightedPPMRequest(
            null,
            (response) => {
                setWeightedPPM(response.data)
            }
        )
        yesterdayContactsRequest(
            null,
            (response) => {
                setYesterdayContacts(response.data)
            }
        )
        weekContactsRequest(
            null,
            (response) => {
                setWeekContacts(response.data)
            }
        )
        monthContactsRequest(
            null,
            (response) => {
                setMonthContacts(response.data)
            }
        )
    }, []);

    return (
        <Grid item container md={15} columns={15} spacing={2}>
            <Grid item lg={2} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Today Revenue"
                    value={todayRevenue.revenue}
                    formatFunction={(amount) => formatCurrency(amount, false)}
                    loading={loadingTodayRevenue}
                    infoTooltipText={"Total revenue earned today"}
                />
            </Grid>

            <Grid item lg={3} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Yesterday Revenue"
                    value={yesterdayRevenue.revenue}
                    averageValue={yesterdayRevenue.avg_revenue}
                    previousValue={yesterdayRevenue.previous_revenue}
                    fullSize={true}
                    formatFunction={(amount) => formatCurrency(amount, false)}
                    complexView={true}
                    loading={loadingYesterdayRevenue}
                    infoTooltipText={"Total revenue earned yesterday"}
                />
            </Grid>

            <Grid item lg={3} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Week Revenue"
                    value={weekRevenue.revenue}
                    averageValue={weekRevenue.avg_revenue}
                    previousValue={weekRevenue.previous_revenue}
                    formatFunction={(amount) => formatCurrency(amount, false)}
                    complexView={true}
                    loading={loadingWeekRevenue}
                    infoTooltipText={"Total revenue earned this week (calculated up to yesterday)"}
                />
            </Grid>

            <Grid item lg={4} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Month Revenue"
                    value={monthRevenue.revenue}
                    averageValue={monthRevenue.avg_revenue}
                    previousValue={monthRevenue.previous_revenue}
                    formatFunction={(amount) => formatCurrency(amount, false)}
                    complexView={true}
                    loading={loadingMonthRevenue}
                    infoTooltipText={"Total revenue earned this month (calculated up to yesterday)"}
                />
            </Grid>

            <Grid item lg={3} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Weighted PPM"
                    value={weightedPPM.current_value}
                    averageValue={weightedPPM.avg_value}
                    previousValue={weightedPPM.previous_value}
                    formatFunction={(amount) => formatCurrency(amount)}
                    complexView={true}
                    loading={loadingWeightedPPM}
                    infoTooltipText={"Weighted PPM: Average revenue per thousand emails sent, adjusted for each campaign's performance."}

                />
            </Grid>

            <Grid item lg={2.5} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Yesterday Unsubscribes"
                    value={yesterdayUnsubscribes.unsubscribes}
                    averageValue={parseInt(yesterdayUnsubscribes.avg_unsubscribes)}
                    previousValue={yesterdayUnsubscribes.previous_unsubscribes}
                    inverseRates={-1}
                    fullSize={true}
                    complexView={true}
                    loading={loadingYesterdayUnsubscribes}
                    infoTooltipText={"Number of users unsubscribed yesterday"}
                    
                />
            </Grid>

            <Grid item lg={2.5} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Week Unsubscribes"
                    value={weekUnsubscribes.unsubscribes}
                    averageValue={parseInt(weekUnsubscribes.avg_unsubscribes)}
                    previousValue={weekUnsubscribes.previous_unsubscribes}
                    inverseRates={-1}
                    complexView={true}
                    loading={loadingWeekUnsubscribes}
                    infoTooltipText={"Number of users unsubscribed this week (up to yesterday)"}
                />
            </Grid>

            <Grid item lg={2.5} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Month Unsubscribes"
                    value={monthUnsubscribes.unsubscribes}
                    averageValue={parseInt(monthUnsubscribes.avg_unsubscribes)}
                    previousValue={monthUnsubscribes.previous_unsubscribes}
                    inverseRates={-1}
                    complexView={true}
                    loading={loadingMonthUnsubscribes}
                    infoTooltipText={"Number of users unsubscribed this month (up to yesterday)"}
                />
            </Grid>

            <Grid item lg={2.5} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Yesterday Contacts"
                    value={yesterdayContacts.contacts}
                    averageValue={parseInt(yesterdayContacts.avg_new_contacts)}
                    previousValue={yesterdayContacts.previous_new_contacts}
                    fullSize={true}
                    loading={loadingYesterdayContacts}
                    complexView={true}
                    infoTooltipText={"Number of new contacts gained yesterday (up to yesterday)"}
                />
            </Grid>

            <Grid item lg={2.5} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Week Contacts"
                    value={weekContacts.contacts}
                    averageValue={parseInt(weekContacts.avg_new_contacts)}
                    previousValue={weekContacts.previous_new_contacts}
                    loading={loadingWeekContacts}
                    complexView={true}
                    infoTooltipText={"Number of new contacts gained this week"}

                />
            </Grid>

            <Grid item lg={2.5} xs={12} sm={6} md={5} sx={{ position: 'relative' }}>
                <StatsCard
                    title="Month Contacts"
                    value={monthContacts.contacts}
                    averageValue={parseInt(monthContacts.avg_new_contacts)}
                    previousValue={monthContacts.previous_new_contacts}
                    loading={loadingMonthContacts}
                    complexView={true}
                    infoTooltipText={"Number of new contacts gained this month (up to yesterday)" }
                />
            </Grid>
        </Grid>
    );
};

export default RevenueStatsCards;