import React, {useEffect, useState} from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {
    Box,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@mui/material";
import ChartCard from "./ChartCard";
import {formatCurrency} from "../../utils/curencyUtils";

import {ChartsXAxis} from '@mui/x-charts/ChartsXAxis';
import {ChartsYAxis} from '@mui/x-charts/ChartsYAxis';
import {axisClasses} from '@mui/x-charts/ChartsAxis';
import {useDropdownSelect} from "./useDropdownSelect";
import useApi from "../../hooks/useApi";
import {dashboardService} from "../../api/services/dashboardService";
import CenteredLoader from "../Loaders/CenteredLoader";
import { ResponsiveContainer } from "recharts";
import { useTheme, useMediaQuery } from "@mui/material";

const METRICS = [
    {key: 'sends', label: 'Sends', disableLine: false},
    {key: 'total_opens', label: 'Total Opens', disableLine: true},
    {key: 'unique_opens', label: 'Unique Opens', disableLine: false},
    {key: 'complaints', label: 'Complaints', disableLine: false},
    {key: 'unsubscribes', label: 'Unsubscribes', disableLine: false},
    {key: 'unique_clicks', label: 'Unique Clicks', disableLine: false},
    {key: 'delivered', label: 'Delivered', disableLine: false},
];

const periods = [
    {label: '7 days', key: 7},
    {label: '30 days', key: 30},
    {label: '60 days', key: 60},
    {label: '90 days', key: 90},
]

const DailyCampaignStatsChartCard = () => {
    // State: which metrics are currently selected (by dataKey).
    // State: which metrics are currently selected (by dataKey).
    const [selectedMetrics, metricsSelector] = useDropdownSelect(
        METRICS, "Metrics", true
    );

    const [selectedPeriod, periodSelector] = useDropdownSelect(
        periods, "Period"
    );

    const [data, setData] = useState([])

    const {loading, makeRequest} = useApi(
        dashboardService.getDailyEspStats,
        true
    )

    useEffect(() => {
        makeRequest(
            selectedPeriod,
            (response) => {
                setData(response.data.reverse())
            }
        )
    }, [selectedPeriod]);

    // Build the `series` array dynamically based on selected metrics
    const chartSeries = METRICS
        .filter((m) => selectedMetrics.includes(m.key))
        .map((m) => ({
            dataKey: m.key,
            label: m.label,
            disableLine: m.disableLine,
        }));

    return (
        <ChartCard
            label={'Daily Campaign Stats'}
            metricsSelector={metricsSelector}
            periodSelector={periodSelector}
            infoTooltipText={"This chart displays daily campaign statistics, allowing you to visualize key performance metrics over time."}
        >
            {loading ? <CenteredLoader height={500}/> : (
                <Box sx={{ width: '100%', height: 500 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            grid={{ vertical: true, horizontal: true }}
                            dataset={data}
                            xAxis={[{
                                dataKey: 'date',
                                scaleType: 'band',
                            }]}
                            yAxis={[{ scaleType: 'linear', label: 'Interactions', min: 0 }]}
                            series={chartSeries}
                            sx={{
                                [`.${axisClasses.left} .${axisClasses.label}`]: { transform: 'translate(-45px, 0)' },
                                [`.${axisClasses.right} .${axisClasses.label}`]: { transform: 'translate(30px, 0)' },
                            }}
                            slotProps={{ legend: { itemGap: 20 } }}
                            margin={{ left: 90, right: 0, top: 100 }}
                        />
                    </ResponsiveContainer>
                </Box>
            )}
        </ChartCard>
    );
};

export default React.memo(DailyCampaignStatsChartCard);