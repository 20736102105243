import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from "react-redux";
import {setSnack} from "../../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import CustomAutocompleteSelectField from "../../../CustomAutocompleteSelectField";
import MaropostIntegration from "../MaropostIntegration";
import CustomModalHeader from "../../../CustomModal/CustomModalHeader";
import CustomModalBody from "../../../CustomModal/CustomModalBody";
import CustomModal from "../../../CustomModal/CustomModal";
import CustomModalFooter from "../../../CustomModal/CustomModalFooter";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {MAROPOST_ID} from "../../../../utils/constants";
import {espIntegrationService} from "../../../../api/services/espIntegrationService";
import ControlledTextField from "../../../ControlledTextField";
import SubmitButton from "../../../SubmitButton";
import CustomAPIAutocompleteSelectField from "../../../CustomAPIAutocompleteSelectField";
import {espService} from "../../../../api/services/espService";


let resolver = {
    name: yup.string().required('Required field'),
    api_key: yup.string().required('Required field').min(1, 'Required'),
    default_account_address: yup.string().required('Required field')
}

let defaultValues = {
    api_key: "",
    name: "",
    maropost_external_account_id: "",
    default_account_address: ""
}

const maropostResolver = {
    maropost_external_account_id: yup.string().required('Required field')
}

const AddEspIntegrationModal = ({setSnack, open, handleClose, handleRefreshItems}) => {

    const [selectedEsp, setSelectedEsp] = useState(null);

    const [loading, setLoading] = useState(false)

    if (!!selectedEsp && selectedEsp.value === MAROPOST_ID) {
        resolver = {
            ...resolver,
            ...maropostResolver
        }
    }

    const {
        control,
        handleSubmit,
        reset
    } = useForm({
        resolver: yupResolver(yup.object().shape(resolver)),
        defaultValues: useMemo(() => defaultValues, [selectedEsp])
    })

    const onChangeSelectedEsp = (newEsp) => {
        setSelectedEsp(newEsp)
    }

    const onSuccess = useCallback((data) => {
        setLoading(true)

        espIntegrationService.createItem({
                ...data,
                esp_id: selectedEsp.value
            }
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                reset()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })
    }, [selectedEsp])


    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create ESP Integration"}/>
            <CustomModalBody>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <CustomAPIAutocompleteSelectField
                            value={selectedEsp}
                            onChange={(value) => onChangeSelectedEsp(value)}
                            label={"ESP"}
                            multiple={false}
                            apiCall={espService.getItems}
                            ignoreInput={true}
                            disableCloseOnSelect={false}
                        />
                    </Grid>
                    {
                        !!selectedEsp && (
                            <>
                                <Grid item xs={12}>
                                    <ControlledTextField control={control} name={"name"} label={"Name"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledTextField control={control} name={"api_key"}
                                                         label={"API KEY"}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ControlledTextField control={control} name={"default_account_address"} label={"Campaign Default Address"}/>
                                </Grid>
                            </>
                        )
                    }
                    {
                        !!selectedEsp && selectedEsp.value === MAROPOST_ID && <MaropostIntegration control={control}/>
                    }
                </Grid>
            </CustomModalBody>

            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create Integration"}
                        loading={loading}
                        disabled={!selectedEsp}
                    />
                </Grid>
            </CustomModalFooter>
        </CustomModal>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddEspIntegrationModal);