import React, {useEffect, useState} from 'react';
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography, useTheme
} from "@mui/material";
import EnhancedTableLoader from "../Table/EnhancedTableLoader";
import {formatCurrency} from "../../utils/curencyUtils";
import useApi from "../../hooks/useApi";
import {dashboardService} from "../../api/services/dashboardService";

// const offers = [
//     {
//         name: "Offer 1",
//         ppm: "14.3",
//         clicks: 143,
//         conversions: 50,
//         network: "ClickBank"
//     },
//     {
//         name: "Offer 2",
//         ppm: "12.3",
//         clicks: 143,
//         conversions: 50,
//         network: "BuyGoods"
//     },
//
//     {
//         name: "Offer 3",
//         ppm: "11.4",
//         clicks: 143,
//         conversions: 50,
//         network: "EverFlow"
//     }
// ]

const TableCellHead = ({children}) => (
    <TableCell>
        <Typography variant={"tableHighlight"}>
            {children}
        </Typography>
    </TableCell>
);

const TableCellBody = ({children}) => (
    <TableCell>
        <Typography variant={"table"}>
            {children}
        </Typography>
    </TableCell>
);

const BestOffersCard = () => {
    const theme = useTheme();

    const [offers, setOffers] = useState([])

    const {loading, makeRequest} = useApi(
        dashboardService.getBestOffers, true
    )

    useEffect(() => {
        makeRequest(
            null,
            (response) => {
                setOffers(response.data)
            }
        )
    }, []);

    const rowHeight = theme.customTokens.defaultRowHeight;
    const maxRows = 10;

    return (
        <Grid container item xs={12}>
            <Grid item md={3}>

            </Grid>
            <Grid item md={12} lg={6}>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 2,
                        pb: 6
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            mb: 2, // Margin below the label + selector
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                color: '#333',
                                flex: 1, // Takes available space to center the label
                                textAlign: 'left', // Adjust alignment
                            }}
                        >
                            Best Offers
                        </Typography>
                    </Box>
                    <Box sx={{width: '100%', position: "relative"}}>
                        {
                            loading && <EnhancedTableLoader/>
                        }
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCellHead>Name</TableCellHead>
                                        <TableCellHead>Network Integration</TableCellHead>
                                        <TableCellHead>Clicks</TableCellHead>
                                        <TableCellHead>Conversion Rate</TableCellHead>
                                        <TableCellHead>PPM</TableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {offers.map((offer, index) => (
                                        <TableRow key={index}>
                                            <TableCellBody>{offer.offer_name}</TableCellBody>
                                            <TableCellBody>{offer.network_integration_name}</TableCellBody>
                                            <TableCellBody>{offer.clicks}</TableCellBody>
                                            <TableCellBody>{offer.conversion_rate.toFixed(2)}%</TableCellBody>
                                            <TableCellBody>{formatCurrency(offer.ppm)}</TableCellBody>
                                        </TableRow>
                                    ))}
                                    {offers.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={5} style={{
                                                textAlign: "center",
                                                height: `${rowHeight * (maxRows - offers.length)}px`
                                            }}>No data to be
                                                displayed</TableCell>
                                        </TableRow>
                                    ) : (
                                        offers.length < maxRows && (
                                            <TableRow>
                                                <TableCell colSpan={5} style={{
                                                    height: `${rowHeight * (maxRows - offers.length)}px`
                                                }}>&nbsp;</TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                </Paper>
            </Grid>
            <Grid item md={3}>

            </Grid>
        </Grid>
    );
};

export default BestOffersCard;