import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import ControlledSelectField from "../../ControlledSelectField";
import {offerService} from "../../../api/services/offerService";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import FormHelperText from "../../FormHelperText";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {externalSourceTypeService} from "../../../api/services/externalSourceTypeService";
import {externalSourceService} from "../../../api/services/externalSourceService";

const option = yup.object({
    key: yup.string().required('Key is required'),
    value: yup.number().typeError('Value must be a number').required('Value is required'),
});

const schema = yup.object().shape({
    external_source_type: option.required('Required field'),
    offer: option.required('Required Field'),
    name: yup.string().required('Required field'),
});

const AddExternalSourceModal = (
    {
        setSnack,
        open,
        handleClose,
        handleRefreshItems,
    }) => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        reset,
        watch
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            external_source_type: null,
            offer: null,
            name: "",
        }
    })

    const onSuccess = (data) => {
        setLoading(true)

        externalSourceService.createItem(
            data.name,
            data.external_source_type.value,
            data.offer.value
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                reset()
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                console.debug(error)
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    return (
        <CustomModal
            open={open}
            // onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Create External Source"}/>
            <CustomModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"name"}
                            label={"Name"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"external_source_type"}
                            label={"Source Type"}
                            apiCall={externalSourceTypeService.getItems}
                            additionalOptionsFields={["network.source_field_name"]}
                            ignoreInput={true}
                            disableCloseOnSelect={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"offer"}
                            label={"Offer"}
                            apiCall={offerService.getItems}
                            ignoreInput={true}
                            disableCloseOnSelect={false}
                        />
                    </Grid>
                </Grid>
            </CustomModalBody>
            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Create External Source"}
                        loading={loading}
                    />
                </Grid>

            </CustomModalFooter>
        </CustomModal>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(AddExternalSourceModal);