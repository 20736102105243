import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import qs from "qs";


const getItems = (args) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()
        const data = {
            page: args.page,
            per_page: args.per_page,
            sort_order: args.sortOrder,
            sort_by: args.sortBy,
            paginate: args.paginate,
            common: args.common,
        }

       if (args.excude_internal !== undefined && args.excude_internal !== null){
            data.excude_internal = args.excude_internal.value
        }

        if (!!args.created_datetime_start && args.created_datetime_start !== "")
            data.created_datetime_start = args.created_datetime_start.format('YYYY-MM-DDTHH:mm:ss.SSS')
        if (!!args.created_datetime_end && args.created_datetime_end !== "")
            data.created_datetime_end = args.created_datetime_end.format('YYYY-MM-DDTHH:mm:ss.SSS')


        if (args.external_source_id) {
            // const ids = args.external_source_id.map(network => network.value)
            data.external_source_id = args.external_source_id.map(item => item.value)
        }

        if (args.network_integration_id) {
            // const ids = args.network_integration_id.map(network => network.value)
            data.network_integration_id = args.network_integration_id.map(item => item.value)
        }
        if (args.external_source_type_id) {
            // const ids = args.external_source_type_id.map(network => network.value)
            data.external_source_type_id = args.external_source_type_id.map(item => item.value)
        }

        if (args.offer_id) {
            // const ids = args.offer_id.map(network => network.value)
            data.offer_id = args.offer_id.map(item => item.value)
        }

        console.debug(data)

        apiClient.get(endpoints.externalSourceReporting, {
            params: data,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: 'repeat'})
            }})
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const externalSourceReportingService = {
    getItems,
}