import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const CenteredLoader = ({height="100%", loaderProps}) => {
    return (
        <Box sx={{
            width: "100%",
            height: height,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <CircularProgress sx={{margin: "auto"}} {...loaderProps}/>
        </Box>
    );
};

export default CenteredLoader;