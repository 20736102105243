import {
    Checkbox, styled,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography, useTheme
} from "@mui/material";
import React from "react";
import EnhancedTableLoader from "./EnhancedTableLoader";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.customTokens.tableHighlightColor,
    },
}));


const EnhancedTableHead = (
    {
        onSelectAllClick,
        sort,
        headCells,
        table,
        actionPerRow = false,
        disableSelecting,
        totals
    }) => {
    const theme = useTheme()

    const allRowsChecked = table.allRowsChecked()
    const intermediate = table.isAnyItemSelected()

    return (
        <TableHead>
            <TableRow sx={{height: theme.customTokens.defaultRowHeight}}>
                {disableSelecting ? null : <StyledTableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        sx={{"display": actionPerRow ? "none" : "block"}}
                        indeterminate={!allRowsChecked && intermediate}
                        checked={allRowsChecked}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </StyledTableCell>}
                {headCells.map((headCell, index) => {
                    if (!!headCell.action) {
                        return <StyledTableCell key={index} sx={{
                            width: theme.customTokens.defaultTableIconWidth,
                        }}/>
                    }
                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align ? headCell.align : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={sort.values.sortBy === headCell.name ? sort.values.sortOrder : false}
                            sx={{
                                width: headCell.width,
                                height: theme.customTokens.defaultRowHeight,
                                overflow: "hidden",
                                py: 1
                            }}
                        >
                            {
                                headCell.label && !headCell.disableSort ? <TableSortLabel
                                        active={headCell.id === sort.values.sortBy}
                                        direction={headCell.id === sort.values.sortBy ? sort.values.sortOrder : 'desc'}
                                        onClick={() => sort.handleSort(headCell.id, headCell.id === sort.values.sortBy && sort.values.sortOrder === "desc" ? "asc" : "desc")}
                                    >
                                        < Typography variant={"tableHighlight"} sx={{
                                            display: "block",
                                            width: "100%",
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                        }}>
                                            {headCell.label}
                                        </Typography>
                                    </TableSortLabel> :
                                    < Typography variant={"tableHighlight"} sx={{
                                        display: "block",
                                        width: "100%",
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }}>
                                        {headCell.label}
                                    </Typography>
                            }

                        </StyledTableCell>
                    )
                })}
            </TableRow>
            {totals && <TableRow sx={{height: theme.customTokens.defaultRowHeight}}>
                {disableSelecting ? null : <StyledTableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        sx={{"display": "none"}}
                        indeterminate={!allRowsChecked && intermediate}
                        checked={allRowsChecked}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </StyledTableCell>}
                {headCells.map((headCell, index) => {
                    if (!!headCell.action) {
                        return <StyledTableCell key={index} sx={{
                            width: theme.customTokens.defaultTableIconWidth,
                        }}/>
                    }

                    let val = headCell.totals;
                    if (headCell.numeric) {
                        let num = parseFloat(val);

                        if (isNaN(num)) {
                            val = "-"; // If the value is not a number, set it to "-"
                        } else {
                            if (headCell.float) {
                                val = num.toFixed(2); // Format to two decimal places if float is true
                            }
                            val = parseFloat(val).toLocaleString(undefined, {minimumFractionDigits: headCell.float ? 2 : 0});

                            if (headCell.trailingSign) {
                                val = val + headCell.trailingSign; // Append trailing sign if specified
                            }
                        }
                    }

                    return (
                        <StyledTableCell
                            key={index}
                            align={headCell.align ? headCell.align : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={sort.values.sortBy === headCell.name ? sort.values.sortOrder : false}
                            sx={{
                                width: headCell.width,
                                height: theme.customTokens.defaultRowHeight,
                                overflow: "hidden",
                                py: 1,
                                top: theme.customTokens.defaultRowHeight
                            }}
                        >
                            < Typography variant={"tableHighlight"} sx={{
                                display: "block",
                                width: "100%",
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}>
                                {val}
                            </Typography>
                        </StyledTableCell>
                    )
                })}
            </TableRow>}
        </TableHead>

    );
}

export default EnhancedTableHead