import React, {useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {offerService} from "../../../api/services/offerService";
import ControlledSelectField from "../../ControlledSelectField";
import DeleteOffer from "../DeleteOffer";
import ControlledAutocompleteSelectField from "../../ControlledAutocompleteSelectField";
import CustomModalHeader from "../../CustomModal/CustomModalHeader";
import CustomModalFooter from "../../CustomModal/CustomModalFooter";
import CustomModal from "../../CustomModal/CustomModal";
import CustomModalBody from "../../CustomModal/CustomModalBody";
import OfferModalLinkHelperText from "./OfferModalLinkHelperText";
import ControlledAPIAutocompleteSelectField from "../../ControlledAPIAutocompleteSelectField";
import {networkIntegrationService} from "../../../api/services/networkIntegrationService";
import {categoryService} from "../../../api/services/categoryService";
import useDataById from "../../../hooks/useDataById";
import CustomAPIAutocompleteSelectField from "../../CustomAPIAutocompleteSelectField";
import {networkService} from "../../../api/services/networkService";


const option = yup.object({
    key: yup.string().required('Key is required'),
    value: yup.number().typeError('Value must be a number').required('Value is required'),
});

const schema = yup.object().shape({
    network_integration: option.required('Required field'),
    category: option.required('Required Field'),
    name: yup.string().required('Required field'),
    link: yup.string().required('Required field'),
});

const EditOfferModal = (
    {
        setSnack,
        open,
        handleClose,
        handleRefreshItems,
        item,
    }
) => {
    const [loading, setLoading] = useState(false)
    const {
        formState,
        control,
        watch,
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            network_integration: item.network_integration ? {
                key: item.network_integration.name,
                value: item.network_integration.id,
            } : null,
            category: item.category ? {
                key: item.category.name,
                value: item.category.id
            } : null,
            name: item.name,
            link: item.link
        }
    })

    const dataById = useDataById(networkIntegrationService.getItems, "network_integration_id")

    const onSuccess = (data) => {
        setLoading(true)

        offerService.updateItem(
            item.id,
            data.name,
            data.link,
            data.network_integration.value,
            data.category.value,
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                handleRefreshItems()
            })
            .catch((error) => {
                setSnack("Invalid Parameters", "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.debug(error)
    }

    // const selectedNetworkIntegration = useWatch({
    //     control,
    //     name: "network_integration"
    // });

    // useEffect(() => {
    //     console.debug("changed")
    //     if (selectedNetworkIntegration)
    //         dataById.fetchDataById([selectedNetworkIntegration])
    // }, [selectedNetworkIntegration]);
    const selectedNetworkIntegration = watch("network_integration")
    console.debug(selectedNetworkIntegration)
    if (selectedNetworkIntegration) {
        const networkIntegrationValues = dataById.items.find(networkIntegration => networkIntegration.id === selectedNetworkIntegration.value)
        if (networkIntegrationValues && networkIntegrationValues.network)
            selectedNetworkIntegration.source_field_name = networkIntegrationValues.network.source_field_name
    }

    return (
        <CustomModal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
            component={"form"}
            onSubmit={handleSubmit(onSuccess, onError)}
        >
            <CustomModalHeader handleClose={handleClose} title={"Edit Offer"}/>
            <CustomModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"network_integration"}
                            label={"Network Integration"}
                            apiCall={networkIntegrationService.getItems}
                            additionalOptionsFields={["source_field_name"]}
                            ignoreInput={true}
                            disableCloseOnSelect={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ControlledAPIAutocompleteSelectField
                            control={control}
                            name={"category"}
                            label={"Category"}
                            apiCall={categoryService.getItems}
                            ignoreInput={true}
                            disableCloseOnSelect={false}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ControlledTextField
                            control={control}
                            name={"name"}
                            label={"Name"}/>
                    </Grid>
                    {/*<Grid item xs={12}>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <OfferModalLinkHelperText selectedNetworkIntegration={selectedNetworkIntegration}/>

                        <ControlledTextField control={control} name={"link"} label={"Link"}/>
                    </Grid>
                </Grid>

            </CustomModalBody>

            <CustomModalFooter>
                <Grid item xs={6}>
                    <Button
                        onClick={() => handleClose()}
                        fullWidth
                        variant={"outlined"}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <SubmitButton
                        text={"Save Offer"}
                        loading={loading}
                        disabled={!formState.isDirty}
                    />
                </Grid>

            </CustomModalFooter>

        </CustomModal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(EditOfferModal);