import {Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import React, {useState} from "react";

export const useDropdownSelect = (options, label, multiple = false) => {
    const [selectedValues, setSelectedValues] = useState(multiple ? options.map(option => option.key) : options[0].key);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedValues(multiple ? (typeof value === 'string' ? value.split(',') : value) : value);
    };

    const htmlElement = (
        <Box sx={{mb: 2, m: "auto"}}>
            <FormControl fullWidth size="small">
                <InputLabel id="select-label">{label}</InputLabel>
                <Select
                    labelId="select-label"
                    id="select"
                    multiple={multiple}
                    fullWidth
                    value={selectedValues}
                    onChange={handleChange}
                    label={label}
                    renderValue={(selected) =>
                        multiple
                            ? selected.map(s => options.find(v => v.key === s)?.label).join(', ')
                            : options.find(v => v.key === selected)?.label
                    }
                >

                    {options.map((value) => (
                        <MenuItem key={value.key} value={value.key}>
                            <Checkbox checked={multiple ? selectedValues.indexOf(value.key) > -1 : selectedValues === value.key}/>
                            <ListItemText primary={value.label}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );

    return [selectedValues, htmlElement];
};